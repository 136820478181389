// import './Paintings.css'

import { useLocation } from "react-router-dom";
import { episodes } from "../../consts";
import './Paintings.css';

export type IPaintings = {
    colors?: string[];
}

export const Paintings = ({colors}: IPaintings) => {
    let { state } = useLocation();
    const x = state as any;
    colors = x?.colors;
    console.log(colors);
    let paintings;
    if (!colors) {
        paintings = episodes;
    } else {
        paintings = episodes.filter((episode) => {
            for (const color of episode.colors) {
                // @ts-ignore
                if (!colors.includes(color)) {
                    return false;
                }
            }
            return true;
        });
    }

    console.log(paintings)
    const paintingsDiv = [];
    for (const painting of paintings) {
        paintingsDiv.push(
            <div className="new-try m-3 col-lg-4 col-sm-6 col-xl-12" key={painting.image}>
                    <a href={painting.link} target="_blank">
                    <img src={`https://paintwithbobross.s3.amazonaws.com/${painting.image.replaceAll(" ", "+")}`} />
                    <div className="layer"></div>
                    <div className="picture-text">
                        <h5 className="episodeSeason">Season {painting.season} Episode {painting.episode}</h5>
                        <h5 className="episodeName">{painting.name}</h5>
                        <p className="episodeDate">{painting.date}</p>
                    </div>
            </a>
                </div>
        );
    }

    return (
        <>
            <div className='container'>
                <div className="row justify-content-center">
                    {paintingsDiv}
                </div>
            </div>
        </>
    )
}