import { useEffect, useState } from 'react';
import './HomePage.css'
import { useNavigate } from 'react-router-dom';
import { colorMap, colors, subColorMap } from '../../consts';
import { Button, Form, InputGroup, ToggleButton } from 'react-bootstrap';

export const HomePage: React.FC = () => {
    const myColors:string[] = [];
    const navigate = useNavigate();
    // const [actualColors, setActualColors] = useState([]);
    let formattedColors:string[] = [];
    const [actualColors, setActualColors] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [allowSubs, setAllowSubs] = useState(true);

    // const click = (e: any) => {
    //     console.log(e)
    // }
    

    // Array.prototype.remove = function(this: any[]) {
    // let what: any;
    // const a: any[] = arguments;
    // let L: number = a.length;
    // let ax: number;

    // while (L && this.length) {
    //     what = a[--L];
    //     while ((ax = this.indexOf(what)) !== -1) {
    //     this.splice(ax, 1);
    //     }
    // }
    // return this;
    // };
    // Material Select Initialization
    // $(document).ready(function() {
    const renderButtons = (): void => {
            // $(window).scrollTop(0)
            // const formattedColors: String[] = [];
            // const myColors: String[] = [];
            
    
            //   var allowSubs = document.getElementById("materialUnchecked").checked;
            const allowSubs = false;

            // @ts-ignore
              if (allowSubs == true){
                // checked (allow subs)
                for (let i = 0; i < myColors.length; i++){
                //   formattedColors.push("br") //dont need red for signature
                //   formattedColors.push("mgw") //dont need mgw
    
                }
              } 
    }
    
    useEffect((): void => {
        renderButtons();  
        const boxes = document.getElementsByClassName('square-box');

        for (let i = 0; i < boxes.length; i++) {
          boxes[i].addEventListener('click', (event:any) => {
              if (event.target.classList.contains('ACTIVE')) {
                setActualColors(actualColors.filter((color) => {
                    return event.target.innerHTML !== color;
                }));
                event.target.classList.remove('ACTIVE');
            } else {
                event.target.classList.add('ACTIVE');
                actualColors.push(event.target.innerHTML);
            }
          });
        }    
    }, [useEffect]);

    const showPaintings = () => {
        // generate formattedColors
        console.log(actualColors)
        for (const color of actualColors) {
            if (allowSubs) {
                // @ts-ignore
                formattedColors = formattedColors.concat(subColorMap[color]);
            } else {
                console.log(color)
                // @ts-ignore
                formattedColors = formattedColors.concat(colorMap[color]);
            }
        }

        navigate('/paintings', {state: {colors: formattedColors}})
    }

    const selectAllCB = (x:any) => {
        console.log(x)
        const boxes = document.getElementsByClassName('spanny');
        
        if (selectAll) {
            setActualColors([]);
        } else {
            setActualColors(colors.map((color) => color));
        }
        
        if (selectAll) {
            for (let i = 0; i < boxes.length; i++) {
                // console.log(boxes[i])
                boxes[i].classList.remove("ACTIVE")
            }
        } else {
            for (let i = 0; i < boxes.length; i++) {
                // console.log(boxes[i])
                boxes[i].classList.add("ACTIVE")
            }
        }
        console.log(actualColors)
        setSelectAll(!selectAll);
    }

    const allowSubsCB = () => {
        setAllowSubs(!allowSubs)
    }
    
    return (
        <>
            <div className='container'>
                
            <div className="row justify-content-center" style={{textAlign: 'center'}}>
                <h1>Please select which colors you have.</h1>
            </div>
            <div id="rowA" className="row justify-content-center">
                <div className="col-lg-6 col-sm-12 square-box" style={{backgroundColor: "#eee9e8"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny" style={{color: "black"}}>Liquid White</span></div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 square-box" style={{backgroundColor: "#0f0f0f"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Liquid Black</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#752521"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Alizarin Crimson</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#753f01"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Burnt Umber</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#d11602"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Bright Red</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#faf33a"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny" style={{color: "black"}}>Cadmium Yellow</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#66410f"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Dark Sienna</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#de960f"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Indian Yellow</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#000000"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Midnight Black</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#2154a6"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Phthalo Blue</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#188f54"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Phthalo Green</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#261d75"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Prussian Blue</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#425813"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Sap Green</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#ffffff"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny" style={{color: "black"}}>Titanium White</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#57310a"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Van Dyke Brown</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-6 square-box" style={{backgroundColor: "#a8692a"}}>
                    <div className="square-content unselectable">
                        <div><span className="spanny">Yellow Ochre</span></div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5 col-lg-4 col-sm-8 col-xs-12" style={{backgroundColor: 'rgba(255, 255, 255, .5)', margin: 'auto'}}>
            <Button
                className='w-auto m-2'
                id="toggle-check"
                // type="checkbox"
                variant="outline-danger"
                active={allowSubs}
                value="1"
                onClick={allowSubsCB}
                >
                Allow substitutions
            </Button>
            <Button
                className='w-auto m-2'
                id="toggle-check"
                // type="checkbox"
                variant="outline-success"
                active={selectAll}
                value="1"
                onClick={selectAllCB}
                >
                Select All
            </Button>
            </div>
            <div className="mt-5 row justify-content-center">
                <button id="go" type="button" onClick={showPaintings} className="w-auto btn btn-primary">Which Bob Ross paintings can I follow along with?</button>
            </div>
            </div>
        </>
    )
}