import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from './components/HomePage/HomePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paintings } from './components/Paintings/Paintings';

function App() {
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-xl navbar-dark bg-dark" style={{ opacity: 0.7 }}>
            <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navtoggle"
            aria-controls="navtoggle"
            aria-expanded="false"
            aria-label="Toggle navigation"
            >
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navtoggle">
            <ul className="list-unstyled navbar-nav ml-auto">
                <li id="homeButton" className="nav-item">
                <a className="nav-link" href="/">
                    Home
                </a>
                </li>
                <li id="allPaintingsButton" className="nav-item">
                <a className="nav-link" href="/paintings">
                    All Paintings
                </a>
                </li>
            </ul>
            </div>
        </nav>
    </header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/paintings" element={<Paintings />} />
          {/* <Route path="/menu" element={<Menu />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about" element={<About />} /> */}
          {/* <Route path="/catering" element={<Menu />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
