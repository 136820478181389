export const episodes = [
  {
      "season": 1,
      "episode": 1,
      "link": "https://youtu.be/oh5p5f5_-7A?list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "A Walk In The Woods",
      "date": "1983-01-11",
      "extension": ".jpg",
      "image": "1 x 1 - A Walk In The Woods - 1983-01-11.jpg"
  },
  {
      "season": 1,
      "episode": 2,
      "link": "https://www.youtube.com/watch?v=RInDWhYceLU&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=2",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Mt McKinley",
      "date": "1983-01-11",
      "extension": ".jpg",
      "image": "1 x 2 - Mt McKinley - 1983-01-11.jpg"
  },
  {
      "season": 1,
      "episode": 3,
      "link": "https://www.youtube.com/watch?v=UOziR7PoVco&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=3",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Ebony Sunset",
      "date": "1983-01-18",
      "extension": ".jpg",
      "image": "1 x 3 - Ebony Sunset - 1983-01-18.jpg"
  },
  {
      "season": 1,
      "episode": 4,
      "link": "https://www.youtube.com/watch?v=0pwoixRikn4&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=4",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Winter Mist",
      "date": "1983-01-25",
      "extension": ".jpg",
      "image": "1 x 4 - Winter Mist - 1983-01-25.jpg"
  },
  {
      "season": 1,
      "episode": 5,
      "link": "https://www.youtube.com/watch?v=DFSIQNjKRfk&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=5",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Quiet Stream",
      "date": "1983-02-01",
      "extension": ".jpg",
      "image": "1 x 5 - Quiet Stream - 1983-02-01.jpg"
  },
  {
      "season": 1,
      "episode": 6,
      "link": "https://www.youtube.com/watch?v=loAzRUzx1wI&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=6",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Winter Moon",
      "date": "1983-02-08",
      "extension": ".jpg",
      "image": "1 x 6 - Winter Moon - 1983-02-08.jpg"
  },
  {
      "season": 1,
      "episode": 7,
      "link": "https://www.youtube.com/watch?v=sDdpc8uisD0&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=7",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Autumn Mountain",
      "date": "1983-02-15",
      "extension": ".jpg",
      "image": "1 x 7 - Autumn Mountain - 1983-02-15.jpg"
  },
  {
      "season": 1,
      "episode": 8,
      "link": "https://www.youtube.com/watch?v=kQlFwTOkYzg&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=8",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Peaceful Valley",
      "date": "1983-02-22",
      "extension": ".jpg",
      "image": "1 x 8 - Peaceful Valley - 1983-02-22.jpg"
  },
  {
      "season": 1,
      "episode": 9,
      "link": "https://www.youtube.com/watch?v=QxcS7p1VHyQ&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=9",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br",
          "mgb"
      ],
      "name": "Seascape",
      "date": "1983-03-01",
      "extension": ".jpg",
      "image": "1 x 9 - Seascape - 1983-03-01.jpg"
  },
  {
      "season": 1,
      "episode": 10,
      "link": "https://www.youtube.com/watch?v=wDnLlywAL5I&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=10",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Mountain Lake",
      "date": "1983-03-08",
      "extension": ".jpg",
      "image": "1 x 10 - Mountain Lake - 1983-03-08.jpg"
  },
  {
      "season": 1,
      "episode": 11,
      "link": "https://www.youtube.com/watch?v=Q03YvknOVe0&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=11",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Winter Glow",
      "date": "1983-03-15",
      "extension": ".jpg",
      "image": "1 x 11 - Winter Glow - 1983-03-15.jpg"
  },
  {
      "season": 1,
      "episode": 12,
      "link": "https://www.youtube.com/watch?v=4E35-8x_y04&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=12",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Snow Fall",
      "date": "1983-03-22",
      "extension": ".jpg",
      "image": "1 x 12 - Snow Fall - 1983-03-22.jpg"
  },
  {
      "season": 1,
      "episode": 13,
      "link": "https://www.youtube.com/watch?v=IEQWfszfRlA&list=PLAEQD0ULngi69x_7JbQvSMprLRK_KSVLu&index=13",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Final Reflections",
      "date": "1983-03-29",
      "extension": ".jpg",
      "image": "1 x 13 - Final Reflections - 1983-03-29.jpg"
  },
  {
      "season": 2,
      "episode": 1,
      "link": " https://www.youtube.com/watch?v=GARWowi0QXI&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=1",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Meadow Lake",
      "date": "1983-08-31",
      "extension": ".jpg",
      "image": "2 x 1 - Meadow Lake - 1983-08-31.jpg"
  },
  {
      "season": 2,
      "episode": 2,
      "link": " https://www.youtube.com/watch?v=VPfYRj4DDco&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=2",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Winter Sun",
      "date": "1983-09-07",
      "extension": ".jpg",
      "image": "2 x 2 - Winter Sun - 1983-09-07.jpg"
  },
  {
      "season": 2,
      "episode": 3,
      "link": " https://www.youtube.com/watch?v=aOJsKNzO3i8&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=3",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Ebony Sea",
      "date": "1983-09-14",
      "extension": ".jpg",
      "image": "2 x 3 - Ebony Sea - 1983-09-14.jpg"
  },
  {
      "season": 2,
      "episode": 4,
      "link": " https://www.youtube.com/watch?v=I-ousb8-SD0&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=4",
      "colors": [
          "blue",
          "brown",
          "white"
      ],
      "name": "Shades of Grey",
      "date": "1983-09-21",
      "extension": ".jpg",
      "image": "2 x 4 - Shades of Grey - 1983-09-21.jpg"
  },
  {
      "season": 2,
      "episode": 5,
      "link": " https://www.youtube.com/watch?v=rTTWw5Gd79I&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=5",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Autumn Splendor",
      "date": "1983-09-28",
      "extension": ".jpg",
      "image": "2 x 5 - Autumn Splendor - 1983-09-28.jpg"
  },
  {
      "season": 2,
      "episode": 6,
      "link": " https://www.youtube.com/watch?v=6O4sfJd8G_M&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=6",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Black River",
      "date": "1983-10-05",
      "extension": ".jpg",
      "image": "2 x 6 - Black River - 1983-10-05.jpg"
  },
  {
      "season": 2,
      "episode": 7,
      "link": " https://www.youtube.com/watch?v=Vx6v47gHBWM&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=7",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Brown Mountain",
      "date": "1983-10-12",
      "extension": ".jpg",
      "image": "2 x 7 - Brown Mountain - 1983-10-12.jpg"
  },
  {
      "season": 2,
      "episode": 8,
      "link": " https://www.youtube.com/watch?v=0FYfo94qefg&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=8",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Reflections",
      "date": "1983-10-19",
      "extension": ".jpg",
      "image": "2 x 8 - Reflections - 1983-10-19.jpg"
  },
  {
      "season": 2,
      "episode": 9,
      "link": " https://www.youtube.com/watch?v=PMDyPrE0puo&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=9",
      "colors": [
          "brown",
          "blue",
          "tw",
          "mb"
      ],
      "name": "Black and White Seascape",
      "date": "1983-10-26",
      "extension": ".jpg",
      "image": "2 x 9 - Black and White Seascape - 1983-10-26.jpg"
  },
  {
      "season": 2,
      "episode": 10,
      "link": " https://www.youtube.com/watch?v=BW2wKKFvH1g&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=10",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lazy River",
      "date": "1983-11-02",
      "extension": ".jpg",
      "image": "2 x 10 - Lazy River - 1983-11-02.jpg"
  },
  {
      "season": 2,
      "episode": 11,
      "link": " https://www.youtube.com/watch?v=GzSqjyQUPZQ&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=11",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Black Waterfall",
      "date": "1983-11-09",
      "extension": ".jpg",
      "image": "2 x 11 - Black Waterfall - 1983-11-09.jpg"
  },
  {
      "season": 2,
      "episode": 12,
      "link": " https://www.youtube.com/watch?v=9jIt95PCFAA&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=12",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Waterfall",
      "date": "1983-11-16",
      "extension": ".jpg",
      "image": "2 x 12 - Mountain Waterfall - 1983-11-16.jpg"
  },
  {
      "season": 2,
      "episode": 13,
      "link": " https://www.youtube.com/watch?v=miJ19Kz_i3Y&list=PLAEQD0ULngi5VAEOviVE6svrUW2axISf6&index=13",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Final Grace",
      "date": "1983-11-23",
      "extension": ".jpg",
      "image": "2 x 13 - Final Grace - 1983-11-23.jpg"
  },
  {
      "season": 3,
      "episode": 1,
      "link": "https://www.youtube.com/watch?v=hoimk4s8JoQ&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Mountain Retreat",
      "date": "1984-01-04",
      "extension": ".jpg",
      "image": "3 x 1 - Mountain Retreat - 1984-01-04.jpg"
  },
  {
      "season": 3,
      "episode": 2,
      "link": "https://www.youtube.com/watch?v=loit61vLUMc&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=2",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Blue Moon",
      "date": "1984-01-11",
      "extension": ".jpg",
      "image": "3 x 2 - Blue Moon - 1984-01-11.jpg"
  },
  {
      "season": 3,
      "episode": 3,
      "link": "https://www.youtube.com/watch?v=fuFalEXVN0k&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=3",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Bubbling Stream",
      "date": "1984-01-18",
      "extension": ".jpg",
      "image": "3 x 3 - Bubbling Stream - 1984-01-18.jpg"
  },
  {
      "season": 3,
      "episode": 4,
      "link": "https://www.youtube.com/watch?v=8ysFkNYwhAE&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=4",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Winter Night",
      "date": "1984-01-25",
      "extension": ".jpg",
      "image": "3 x 4 - Winter Night - 1984-01-25.jpg"
  },
  {
      "season": 3,
      "episode": 5,
      "link": "https://www.youtube.com/watch?v=8Zge88tVwjE&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=5",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Distant Hills",
      "date": "1984-02-01",
      "extension": ".jpg",
      "image": "3 x 5 - Distant Hills - 1984-02-01.jpg"
  },
  {
      "season": 3,
      "episode": 6,
      "link": "https://www.youtube.com/watch?v=OHSm8kLE7js&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=6",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Covered Bridge",
      "date": "1984-02-08",
      "extension": ".jpg",
      "image": "3 x 6 - Covered Bridge - 1984-02-08.jpg"
  },
  {
      "season": 3,
      "episode": 7,
      "link": "https://www.youtube.com/watch?v=9N5IWKzYIyU&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=7",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Quiet Inlet",
      "date": "1984-02-15",
      "extension": ".jpg",
      "image": "3 x 7 - Quiet Inlet - 1984-02-15.jpg"
  },
  {
      "season": 3,
      "episode": 8,
      "link": "https://www.youtube.com/watch?v=l141Y0x8om0&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=8",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Night Light",
      "date": "1984-02-22",
      "extension": ".jpg",
      "image": "3 x 8 - Night Light - 1984-02-22.jpg"
  },
  {
      "season": 3,
      "episode": 9,
      "link": "https://www.youtube.com/watch?v=OFKFUJ9eDNs&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=9",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "The Old Mill",
      "date": "1984-02-29",
      "extension": ".jpg",
      "image": "3 x 9 - The Old Mill - 1984-02-29.jpg"
  },
  {
      "season": 3,
      "episode": 10,
      "link": "https://www.youtube.com/watch?v=L5bXkI0-pEg&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=10",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Campfire",
      "date": "1984-03-07",
      "extension": ".jpg",
      "image": "3 x 10 - Campfire - 1984-03-07.jpg"
  },
  {
      "season": 3,
      "episode": 11,
      "link": "https://www.youtube.com/watch?v=WJJwrnFhUUg&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=11",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Rustic Barn",
      "date": "1984-03-14",
      "extension": ".jpg",
      "image": "3 x 11 - Rustic Barn - 1984-03-14.jpg"
  },
  {
      "season": 3,
      "episode": 12,
      "link": "https://www.youtube.com/watch?v=P_DaqkFbnac&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=12",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Hidden Lake",
      "date": "1984-03-21",
      "extension": ".jpg",
      "image": "3 x 12 - Hidden Lake - 1984-03-21.jpg"
  },
  {
      "season": 3,
      "episode": 13,
      "link": "https://www.youtube.com/watch?v=Z0vtjRLqXcQ&list=PLAEQD0ULngi7zDD6O36FKkEHse-JCdVvh&index=13",
      "colors": [
          "tw",
          "pg",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "br"
      ],
      "name": "Peaceful Waters",
      "date": "1984-03-28",
      "extension": ".jpg",
      "image": "3 x 13 - Peaceful Waters - 1984-03-28.jpg"
  },
  {
      "season": 4,
      "episode": 1,
      "link": "https://www.youtube.com/watch?v=hP4GfMgsNVE&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=1",
      "colors": [
          "tw",
          "pb",
          "vdb",
          "ac"
      ],
      "name": "Purple Splendor",
      "date": "1984-09-05",
      "extension": ".jpg",
      "image": "4 x 1 - Purple Splendor - 1984-09-05.jpg"
  },
  {
      "season": 4,
      "episode": 2,
      "link": "https://www.youtube.com/watch?v=PbchoOWWCZs&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=2",
      "colors": [
          "tw",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Tranquil Valley",
      "date": "1984-09-12",
      "extension": ".jpg",
      "image": "4 x 2 - Tranquil Valley - 1984-09-12.jpg"
  },
  {
      "season": 4,
      "episode": 3,
      "link": "https://www.youtube.com/watch?v=NcVeRlPu_5w&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=3",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Majestic Mountains",
      "date": "1984-09-19",
      "extension": ".jpg",
      "image": "4 x 3 - Majestic Mountains - 1984-09-19.jpg"
  },
  {
      "season": 4,
      "episode": 4,
      "link": "https://www.youtube.com/watch?v=lmKAwKrONmE&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=4",
      "colors": [
          "mb",
          "bu",
          "iy",
          "prb",
          "ac",
          "tw"
      ],
      "name": "Winter Sawscape",
      "date": "1984-09-26",
      "extension": ".jpg",
      "image": "4 x 4 - Winter Sawscape - 1984-09-26.jpg"
  },
  {
      "season": 4,
      "episode": 5,
      "link": "https://www.youtube.com/watch?v=y5k4GXw_-yI&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=5",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "cy",
          "br"
      ],
      "name": "Evening Seascape",
      "date": "1984-10-03",
      "extension": ".jpg",
      "image": "4 x 5 - Evening Seascape - 1984-10-03.jpg"
  },
  {
      "season": 4,
      "episode": 6,
      "link": "https://www.youtube.com/watch?v=GBN9AIw3Ao4&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=6",
      "colors": [
          "tw",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Warm Summer Day",
      "date": "1984-10-10",
      "extension": ".jpg",
      "image": "4 x 6 - Warm Summer Day - 1984-10-10.jpg"
  },
  {
      "season": 4,
      "episode": 7,
      "link": "https://www.youtube.com/watch?v=81QKellPA70&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=7",
      "colors": [
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Cabin in the Woods",
      "date": "1984-10-17",
      "extension": ".jpg",
      "image": "4 x 7 - Cabin in the Woods - 1984-10-17.jpg"
  },
  {
      "season": 4,
      "episode": 8,
      "link": "https://www.youtube.com/watch?v=2FpVyGanPwM&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=8",
      "colors": [
          "tw",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Wetlands",
      "date": "1984-10-24",
      "extension": ".jpg",
      "image": "4 x 8 - Wetlands - 1984-10-24.jpg"
  },
  {
      "season": 4,
      "episode": 9,
      "link": "https://www.youtube.com/watch?v=tJRvBcqQd5A&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=9",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Cool Waters",
      "date": "1984-10-31",
      "extension": ".jpg",
      "image": "4 x 9 - Cool Waters - 1984-10-31.jpg"
  },
  {
      "season": 4,
      "episode": 10,
      "link": "https://www.youtube.com/watch?v=wIZX57I4aDs&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=10",
      "colors": [
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Quiet Woods",
      "date": "1984-11-07",
      "extension": ".jpg",
      "image": "4 x 10 - Quiet Woods - 1984-11-07.jpg"
  },
  {
      "season": 4,
      "episode": 11,
      "link": "https://www.youtube.com/watch?v=9DU6bunvD_g&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=11",
      "colors": [
          "tw",
          "cy",
          "iy",
          "br",
          "ac",
          "prb",
          "vdb",
          "bu",
          "sg"
      ],
      "name": "Northwest Majesty",
      "date": "1984-11-14",
      "extension": ".jpg",
      "image": "4 x 11 - Northwest Majesty - 1984-11-14.jpg"
  },
  {
      "season": 4,
      "episode": 12,
      "link": "https://www.youtube.com/watch?v=VAdMkf-AAPM&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=12",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Autumn Days",
      "date": "1984-11-21",
      "extension": ".jpg",
      "image": "4 x 12 - Autumn Days - 1984-11-21.jpg"
  },
  {
      "season": 4,
      "episode": 13,
      "link": "https://www.youtube.com/watch?v=o2cjLA_wgIk&list=PLAEQD0ULngi56KMlB1P_wK9pJZcMpEFQd&index=13",
      "colors": [
          "nmw",
          "tw",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "mgb",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Mountain Challenge",
      "date": "1984-11-28",
      "extension": ".jpg",
      "image": "4 x 13 - Mountain Challenge - 1984-11-28.jpg"
  },
  {
      "season": 5,
      "episode": 1,
      "link": "https://www.youtube.com/watch?v=DqhzxdkdQS0&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=1",
      "colors": [
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Mountain Waterfall",
      "date": "1985-01-02",
      "extension": ".jpg",
      "image": "5 x 1 - Mountain Waterfall - 1985-01-02.jpg"
  },
  {
      "season": 5,
      "episode": 2,
      "link": "https://www.youtube.com/watch?v=6evqNlOO7Bw&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=2",
      "colors": [
          "tw",
          "pg",
          "pb",
          "nmw",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br",
          "mgb"
      ],
      "name": "Twilight Meadow",
      "date": "1985-01-09",
      "extension": ".jpg",
      "image": "5 x 2 - Twilight Meadow - 1985-01-09.jpg"
  },
  {
      "season": 5,
      "episode": 3,
      "link": "https://www.youtube.com/watch?v=UVhhStJAJZc&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=3",
      "colors": [
          "tw",
          "pg",
          "mgb",
          "pb",
          "ac"
      ],
      "name": "Mountain Blossoms",
      "date": "1985-01-16",
      "extension": ".jpg",
      "image": "5 x 3 - Mountain Blossoms - 1985-01-16.jpg"
  },
  {
      "season": 5,
      "episode": 4,
      "link": "https://www.youtube.com/watch?v=qg9c1SqdRko&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=4",
      "colors": [
          "tw",
          "pb",
          "bu",
          "iy",
          "vdb",
          "ac",
          "sg"
      ],
      "name": "Winter Stillness",
      "date": "1985-01-23",
      "extension": ".jpg",
      "image": "5 x 4 - Winter Stillness - 1985-01-23.jpg"
  },
  {
      "season": 5,
      "episode": 5,
      "link": "https://www.youtube.com/watch?v=aiK9xOIJtV8&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=5",
      "colors": [
          "tw",
          "pb",
          "bu",
          "ac",
          "vdb",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Quiet Pond",
      "date": "1985-01-30",
      "extension": ".jpg",
      "image": "5 x 5 - Quiet Pond - 1985-01-30.jpg"
  },
  {
      "season": 5,
      "episode": 6,
      "link": "https://www.youtube.com/watch?v=u0Bz6TNUK1Q&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=6",
      "colors": [
          "mgb",
          "tw",
          "prb",
          "vdb",
          "ac",
          "iy"
      ],
      "name": "Ocean Sunrise",
      "date": "1985-02-06",
      "extension": ".jpg",
      "image": "5 x 6 - Ocean Sunrise - 1985-02-06.jpg"
  },
  {
      "season": 5,
      "episode": 7,
      "link": "https://www.youtube.com/watch?v=LiKCzeqn-kg&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=7",
      "colors": [
          "nmw",
          "tw",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Bubbling Brook",
      "date": "1985-02-13",
      "extension": ".jpg",
      "image": "5 x 7 - Bubbling Brook - 1985-02-13.jpg"
  },
  {
      "season": 5,
      "episode": 8,
      "link": "https://www.youtube.com/watch?v=spFwCh2616s&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=8",
      "colors": [
          "tw",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Arizona Splendor",
      "date": "1985-02-20",
      "extension": ".jpg",
      "image": "5 x 8 - Arizona Splendor - 1985-02-20.jpg"
  },
  {
      "season": 5,
      "episode": 9,
      "link": "https://www.youtube.com/watch?v=DFQlu6eqrBo&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=9",
      "colors": [
          "tw",
          "cy",
          "pb",
          "ac",
          "pg"
      ],
      "name": "Anatomy of a Wave",
      "date": "1985-02-27",
      "extension": ".jpg",
      "image": "5 x 9 - Anatomy of a Wave - 1985-02-27.jpg"
  },
  {
      "season": 5,
      "episode": 10,
      "link": "https://www.youtube.com/watch?v=xj8xsTSkbUk&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=10",
      "colors": [
          "tw",
          "mgb",
          "pg",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "yo",
          "br"
      ],
      "name": "The Windmill",
      "date": "1985-03-06",
      "extension": ".jpg",
      "image": "5 x 10 - The Windmill - 1985-03-06.jpg"
  },
  {
      "season": 5,
      "episode": 11,
      "link": "https://www.youtube.com/watch?v=yTzlm_t0RmY&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=11",
      "colors": [
          "tw",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy"
      ],
      "name": "Autumn Glory",
      "date": "1985-03-13",
      "extension": ".jpg",
      "image": "5 x 11 - Autumn Glory - 1985-03-13.jpg"
  },
  {
      "season": 5,
      "episode": 12,
      "link": "https://www.youtube.com/watch?v=2uXMx8Kjs8I&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=12",
      "colors": [
          "ds",
          "iy",
          "cy",
          "bu",
          "blue",
          "tw"
      ],
      "name": "Indian Girl",
      "date": "1985-03-20",
      "extension": ".jpg",
      "image": "5 x 12 - Indian Girl - 1985-03-20.jpg"
  },
  {
      "season": 5,
      "episode": 13,
      "link": "https://www.youtube.com/watch?v=87MMbN1bQCs&list=PLAEQD0ULngi6bAFRfcqgpKP4T4SnoxoAz&index=13",
      "colors": [
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Meadow Stream",
      "date": "1985-03-27",
      "extension": ".jpg",
      "image": "5 x 13 - Meadow Stream - 1985-03-27.jpg"
  },
  {
      "season": 6,
      "episode": 1,
      "link": "https://www.youtube.com/watch?v=LygUyAb78oY&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=1",
      "colors": [
          "tw",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br",
          "mgb"
      ],
      "name": "Blue River",
      "date": "1985-05-01",
      "extension": ".jpg",
      "image": "6 x 1 - Blue River - 1985-05-01.jpg"
  },
  {
      "season": 6,
      "episode": 2,
      "link": "https://www.youtube.com/watch?v=Bcqyzo85A1o&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=2",
      "colors": [
          "tw",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Nature's Edge",
      "date": "1985-05-08",
      "extension": ".jpg",
      "image": "6 x 2 - Nature's Edge - 1985-05-08.jpg"
  },
  {
      "season": 6,
      "episode": 3,
      "link": "https://www.youtube.com/watch?v=wbZreRaE74k&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=3",
      "colors": [
          "tw",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Morning Mist",
      "date": "1985-05-15",
      "extension": ".jpg",
      "image": "6 x 3 - Morning Mist - 1985-05-15.jpg"
  },
  {
      "season": 6,
      "episode": 4,
      "link": "https://www.youtube.com/watch?v=QglIjlqsUdU&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=4",
      "colors": [
          "tw",
          "pb",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Whispering Stream",
      "date": "1985-05-22",
      "extension": ".jpg",
      "image": "6 x 4 - Whispering Stream - 1985-05-22.jpg"
  },
  {
      "season": 6,
      "episode": 5,
      "link": "https://www.youtube.com/watch?v=USkduOfwJok&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=5",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Secluded Forest",
      "date": "1985-05-29",
      "extension": ".jpg",
      "image": "6 x 5 - Secluded Forest - 1985-05-29.jpg"
  },
  {
      "season": 6,
      "episode": 6,
      "link": "https://www.youtube.com/watch?v=n9EsFFtwZnE&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=6",
      "colors": [
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "cy",
          "yo"
      ],
      "name": "Snow Trail",
      "date": "1985-06-05",
      "extension": ".jpg",
      "image": "6 x 6 - Snow Trail - 1985-06-05.jpg"
  },
  {
      "season": 6,
      "episode": 7,
      "link": "https://www.youtube.com/watch?v=UQ-RTZCOQn0&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=7",
      "colors": [
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "mgb",
          "yo",
          "br"
      ],
      "name": "Arctic Beauty",
      "date": "1985-06-12",
      "extension": ".jpg",
      "image": "6 x 7 - Arctic Beauty - 1985-06-12.jpg"
  },
  {
      "season": 6,
      "episode": 8,
      "link": "https://www.youtube.com/watch?v=m6UM-rN2D6s&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=8",
      "colors": [
          "tw",
          "prb",
          "vdb",
          "ac",
          "cy",
          "iy",
          "br"
      ],
      "name": "Horizons West",
      "date": "1985-06-19",
      "extension": ".jpg",
      "image": "6 x 8 - Horizons West - 1985-06-19.jpg"
  },
  {
      "season": 6,
      "episode": 9,
      "link": "https://www.youtube.com/watch?v=dafH8ks9Zww&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=9",
      "colors": [
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "High Chateau",
      "date": "1985-06-26",
      "extension": ".jpg",
      "image": "6 x 9 - High Chateau - 1985-06-26.jpg"
  },
  {
      "season": 6,
      "episode": 10,
      "link": "https://www.youtube.com/watch?v=j8Jf7QVKLgQ&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=10",
      "colors": [
          "tw",
          "prb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Country Life",
      "date": "1985-07-02",
      "extension": ".jpg",
      "image": "6 x 10 - Country Life - 1985-07-02.jpg"
  },
  {
      "season": 6,
      "episode": 11,
      "link": "https://www.youtube.com/watch?v=MTInkV5ODjk&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=11",
      "colors": [
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "yo",
          "br"
      ],
      "name": "Western Expanse",
      "date": "1985-07-09",
      "extension": ".jpg",
      "image": "6 x 11 - Western Expanse - 1985-07-09.jpg"
  },
  {
      "season": 6,
      "episode": 12,
      "link": "https://www.youtube.com/watch?v=Ugiwi8uizpg&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=12",
      "colors": [
          "tw",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Marshlands",
      "date": "1985-07-16",
      "extension": ".jpg",
      "image": "6 x 12 - Marshlands - 1985-07-16.jpg"
  },
  {
      "season": 6,
      "episode": 13,
      "link": "https://www.youtube.com/watch?v=se7wOprxRGs&list=PLAEQD0ULngi5UR35RJsvL0Xvlm3oeY4Ma&index=13",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "bu",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Blaze of Color",
      "date": "1985-07-23",
      "extension": ".jpg",
      "image": "6 x 13 - Blaze of Color - 1985-07-23.jpg"
  },
  {
      "season": 7,
      "episode": 1,
      "link": "https://www.youtube.com/watch?,v=kdlHV6ceI_g&list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1&index=1",
      "colors": [
          "tw",
          "prb",
          "ds",
          "vdb",
          "yo",
          "br"
      ],
      "name": "Winter Cabin",
      "date": "1985-10-02",
      "extension": ".jpg",
      "image": "7 x 1 - Winter Cabin - 1985-10-02.jpg"
  },
  {
      "season": 7,
      "episode": 2,
      "link": "https://www.youtube.com/watch?v=2OxSJcFvpoU&list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1&index=2",
      "colors": [
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Secluded Lake",
      "date": "1985-10-09",
      "extension": ".jpg",
      "image": "7 x 2 - Secluded Lake - 1985-10-09.jpg"
  },
  {
      "season": 7,
      "episode": 3,
      "link": "https://youtu.be/YQPEy3hYGo8?lis,t=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "tw",
          "mb",
          "ac",
          "sg",
          "cy",
          "mgb",
          "iy",
          "nmw",
          "br"
      ],
      "name": "Evergreens at Sunset",
      "date": "1985-10-16",
      "extension": ".jpg",
      "image": "7 x 3 - Evergreens at Sunset - 1985-10-16.jpg"
  },
  {
      "season": 7,
      "episode": 4,
      "link": "https://www.youtube.com/watch?v=E3IAMvO8GyM&list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1&index=4",
      "colors": [
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Cabin",
      "date": "1985-10-23",
      "extension": ".jpg",
      "image": "7 x 4 - Mountain Cabin - 1985-10-23.jpg"
  },
  {
      "season": 7,
      "episode": 5,
      "link": "https://youtu.be/MHJB0IBnuD4?list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "bu",
          "ds",
          "red",
          "blue",
          "yellow",
          "ac",
          "prb",
          "green"
      ],
      "name": "Portrait of Sally",
      "date": "1985-10-30",
      "extension": ".jpg",
      "image": "7 x 5 - Portrait of Sally - 1985-10-30.jpg"
  },
  {
      "season": 7,
      "episode": 6,
      "link": "https://youtu.be/530_cVmexiI?list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "yo",
          "iy",
          "br"
      ],
      "name": "Misty Waterfall",
      "date": "1985-11-06",
      "extension": ".jpg",
      "image": "7 x 6 - Misty Waterfall - 1985-11-06.jpg"
  },
  {
      "season": 7,
      "episode": 7,
      "link": "https://youtu.be/WT6n0K2zGnA?list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Barn at Sunset",
      "date": "1985-11-13",
      "extension": ".jpg",
      "image": "7 x 7 - Barn at Sunset - 1985-11-13.jpg"
  },
  {
      "season": 7,
      "episode": 8,
      "link": "https://youtu.be/GhOGZMpPUSE?list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Splendor",
      "date": "1985-11-20",
      "extension": ".jpg",
      "image": "7 x 8 - Mountain Splendor - 1985-11-20.jpg"
  },
  {
      "season": 7,
      "episode": 9,
      "link": "https://youtu.be/yAiYirlcq7o?list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lake by Mountain",
      "date": "1985-11-27",
      "extension": ".jpg",
      "image": "7 x 9 - Lake by Mountain - 1985-11-27.jpg"
  },
  {
      "season": 7,
      "episode": 10,
      "link": "https://youtu.be/0M9pwLHRR2c?list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Glory",
      "date": "1985-12-04",
      "extension": ".jpg",
      "image": "7 x 10 - Mountain Glory - 1985-12-04.jpg"
  },
  {
      "season": 7,
      "episode": 11,
      "link": "https://www.youtube.com/watch?v=sS-hNYgDUak",
      "colors": [
          "tw",
          "mb"
      ],
      "name": "Grey Winter",
      "date": "1985-12-11",
      "extension": ".jpg",
      "image": "7 x 11 - Grey Winter - 1985-12-11.jpg"
  },
  {
      "season": 7,
      "episode": 12,
      "link": "https://youtu.be/4KYxkqlzyqM?lis,t=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Dock Scene",
      "date": "1985-12-18",
      "extension": ".jpg",
      "image": "7 x 12 - Dock Scene - 1985-12-18.jpg"
  },
  {
      "season": 7,
      "episode": 13,
      "link": "https://youtu.be/VqMbL00eZqw?list=PLAEQD0ULngi5oKehJMOnVTetKSjfmFrT1",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Dark Waterfall",
      "date": "1985-12-25",
      "extension": ".jpg",
      "image": "7 x 13 - Dark Waterfall - 1985-12-25.jpg"
  },
  {
      "season": 8,
      "episode": 1,
      "link": "https://youtu.be/cC5ozePVKGI?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pb",
          "mb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Misty Rolling Hills",
      "date": "1986-01-02",
      "extension": ".jpg",
      "image": "8 x 1 - Misty Rolling Hills - 1986-01-02.jpg"
  },
  {
      "season": 8,
      "episode": 2,
      "link": "https://youtu.be/CP6_5cQVZvQ?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lakeside Cabin",
      "date": "1986-01-09",
      "extension": ".jpg",
      "image": "8 x 2 - Lakeside Cabin - 1986-01-09.jpg"
  },
  {
      "season": 8,
      "episode": 3,
      "link": "https://youtu.be/mUJoNLWQ1yI?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Warm Winter Day",
      "date": "1986-01-16",
      "extension": ".jpg",
      "image": "8 x 3 - Warm Winter Day - 1986-01-16.jpg"
  },
  {
      "season": 8,
      "episode": 4,
      "link": "https://youtu.be/uj5FE70BcB0?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Waterside Way",
      "date": "1986-01-23",
      "extension": ".jpg",
      "image": "8 x 4 - Waterside Way - 1986-01-23.jpg"
  },
  {
      "season": 8,
      "episode": 5,
      "link": "https://youtu.be/Da4SPyh1ATM?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Hunter's Haven",
      "date": "1986-01-30",
      "extension": ".jpg",
      "image": "8 x 5 - Hunter's Haven - 1986-01-30.jpg"
  },
  {
      "season": 8,
      "episode": 6,
      "link": "https://youtu.be/U2_SKgM3f4A?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Bubbling Mountain Brook",
      "date": "1986-02-06",
      "extension": ".jpg",
      "image": "8 x 6 - Bubbling Mountain Brook - 1986-02-06.jpg"
  },
  {
      "season": 8,
      "episode": 7,
      "link": "https://youtu.be/19oz9XHZNzA?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Winter Hideaway",
      "date": "1986-02-13",
      "extension": ".jpg",
      "image": "8 x 7 - Winter Hideaway - 1986-02-13.jpg"
  },
  {
      "season": 8,
      "episode": 8,
      "link": "https://youtu.be/cIUBUc_ITBc?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Foot of the Mountain",
      "date": "1986-02-20",
      "extension": ".jpg",
      "image": "8 x 8 - Foot of the Mountain - 1986-02-20.jpg"
  },
  {
      "season": 8,
      "episode": 9,
      "link": "https://youtu.be/x5CoQj9zr-c?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Majestic Pine",
      "date": "1986-02-27",
      "extension": ".jpg",
      "image": "8 x 9 - Majestic Pine - 1986-02-27.jpg"
  },
  {
      "season": 8,
      "episode": 10,
      "link": "https://youtu.be/XBqD3QhKU24?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "mb",
          "nmw",
          "ds",
          "mgb",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Cactus at Sunset",
      "date": "1986-03-06",
      "extension": ".jpg",
      "image": "8 x 10 - Cactus at Sunset - 1986-03-06.jpg"
  },
  {
      "season": 8,
      "episode": 11,
      "link": "https://youtu.be/V_l6olF3yHI?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pb",
          "prb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Range",
      "date": "1986-03-13",
      "extension": ".jpg",
      "image": "8 x 11 - Mountain Range - 1986-03-13.jpg"
  },
  {
      "season": 8,
      "episode": 12,
      "link": "https://youtu.be/qTDQt_PdlYc?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lonely Retreat",
      "date": "1986-03-20",
      "extension": ".jpg",
      "image": "8 x 12 - Lonely Retreat - 1986-03-20.jpg"
  },
  {
      "season": 8,
      "episode": 13,
      "link": "https://youtu.be/vgbMONXc9Cs?list=PLAEQD0ULngi7_Td-kv4YRaDwJUpUuz0WR",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "br"
      ],
      "name": "Northern Lights",
      "date": "1986-03-27",
      "extension": ".jpg",
      "image": "8 x 13 - Northern Lights - 1986-03-27.jpg"
  },
  {
      "season": 9,
      "episode": 1,
      "link": "https://youtu.be/O6L5YPt9CeU?lis,t=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "yo",
          "br"
      ],
      "name": "Winter Evergreens",
      "date": "1986-04-30",
      "extension": ".jpg",
      "image": "9 x 1 - Winter Evergreens - 1986-04-30.jpg"
  },
  {
      "season": 9,
      "episode": 2,
      "link": "https://youtu.be/ZKxFvyyOBPQ?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Surf's Up",
      "date": "1986-05-07",
      "extension": ".jpg",
      "image": "9 x 2 - Surf's Up - 1986-05-07.jpg"
  },
  {
      "season": 9,
      "episode": 3,
      "link": "https://youtu.be/e5JhYi_G-l0?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "mgb",
          "nmw",
          "iy",
          "ac",
          "pb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "cy",
          "yo",
          "br"
      ],
      "name": "Red Sunset",
      "date": "1986-05-14",
      "extension": ".jpg",
      "image": "9 x 3 - Red Sunset - 1986-05-14.jpg"
  },
  {
      "season": 9,
      "episode": 4,
      "link": "https://youtu.be/u5VT_WGM0kg?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Meadow Road",
      "date": "1986-05-21",
      "extension": ".jpg",
      "image": "9 x 4 - Meadow Road - 1986-05-21.jpg"
  },
  {
      "season": 9,
      "episode": 5,
      "link": "https://youtu.be/pYWiLm_-sXw?lis,t=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "br"
      ],
      "name": "Winter Oval",
      "date": "1986-05-28",
      "extension": ".jpg",
      "image": "9 x 5 - Winter Oval - 1986-05-28.jpg"
  },
  {
      "season": 9,
      "episode": 6,
      "link": "https://youtu.be/WGUcw_kFvzU?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Secluded Beach",
      "date": "1986-06-04",
      "extension": ".jpg",
      "image": "9 x 6 - Secluded Beach - 1986-06-04.jpg"
  },
  {
      "season": 9,
      "episode": 7,
      "link": "https://youtu.be/E3XW_Zp238U?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Forest Hills",
      "date": "1986-06-11",
      "extension": ".jpg",
      "image": "9 x 7 - Forest Hills - 1986-06-11.jpg"
  },
  {
      "season": 9,
      "episode": 8,
      "link": "https://youtu.be/3CDg_9gL-5M?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Little House by the Road",
      "date": "1986-06-18",
      "extension": ".jpg",
      "image": "9 x 8 - Little House by the Road - 1986-06-18.jpg"
  },
  {
      "season": 9,
      "episode": 9,
      "link": "https://youtu.be/e0VUprkc1n0?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Path",
      "date": "1986-06-25",
      "extension": ".jpg",
      "image": "9 x 9 - Mountain Path - 1986-06-25.jpg"
  },
  {
      "season": 9,
      "episode": 10,
      "link": "https://youtu.be/9wp8NRzCJnw?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Country Charm",
      "date": "1986-07-02",
      "extension": ".jpg",
      "image": "9 x 10 - Country Charm - 1986-07-02.jpg"
  },
  {
      "season": 9,
      "episode": 11,
      "link": "https://youtu.be/7yU55PUls2c?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Nature's Paradise",
      "date": "1986-07-09",
      "extension": ".jpg",
      "image": "9 x 11 - Nature's Paradise - 1986-07-09.jpg"
  },
  {
      "season": 9,
      "episode": 12,
      "link": "https://youtu.be/so--opB-yuQ?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Mountain by the Sea",
      "date": "1986-07-16",
      "extension": ".jpg",
      "image": "9 x 12 - Mountain by the Sea - 1986-07-16.jpg"
  },
  {
      "season": 9,
      "episode": 13,
      "link": "https://youtu.be/q48EWPrbzVs?list=PLAEQD0ULngi6eJ6Sry9JCR0afC1HEhMjo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Hideaway",
      "date": "1986-07-23",
      "extension": ".jpg",
      "image": "9 x 13 - Mountain Hideaway - 1986-07-23.jpg"
  },
  {
      "season": 10,
      "episode": 1,
      "link": "https://youtu.be/1s58rW0_LN4?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Towering Peaks",
      "date": "1986-09-03",
      "extension": ".jpg",
      "image": "10 x 1 - Towering Peaks - 1986-09-03.jpg"
  },
  {
      "season": 10,
      "episode": 2,
      "link": "https://youtu.be/a6Wil3OXNuI?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Cabin at Sunset",
      "date": "1986-09-10",
      "extension": ".jpg",
      "image": "10 x 2 - Cabin at Sunset - 1986-09-10.jpg"
  },
  {
      "season": 10,
      "episode": 3,
      "link": "https://youtu.be/cMwkY8ojRik?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Twin Falls",
      "date": "1986-09-17",
      "extension": ".jpg",
      "image": "10 x 3 - Twin Falls - 1986-09-17.jpg"
  },
  {
      "season": 10,
      "episode": 4,
      "link": "https://youtu.be/vrAMRxBB5KI?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Secluded Bridge",
      "date": "1986-09-24",
      "extension": ".jpg",
      "image": "10 x 4 - Secluded Bridge - 1986-09-24.jpg"
  },
  {
      "season": 10,
      "episode": 5,
      "link": "https://youtu.be/XZmdzfvXRuw?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Ocean Breeze",
      "date": "1986-10-01",
      "extension": ".jpg",
      "image": "10 x 5 - Ocean Breeze - 1986-10-01.jpg"
  },
  {
      "season": 10,
      "episode": 6,
      "link": "https://youtu.be/vKyMU5Z8cDI?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Autumn Woods",
      "date": "1986-10-08",
      "extension": ".jpg",
      "image": "10 x 6 - Autumn Woods - 1986-10-08.jpg"
  },
  {
      "season": 10,
      "episode": 7,
      "link": "https://youtu.be/ov4YaCQB9co?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "br"
      ],
      "name": "Winter Solitude",
      "date": "1986-10-15",
      "extension": ".jpg",
      "image": "10 x 7 - Winter Solitude - 1986-10-15.jpg"
  },
  {
      "season": 10,
      "episode": 8,
      "link": "https://youtu.be/CiNDyckERXQ?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "nmw",
          "mgb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Golden Sunset",
      "date": "1986-10-22",
      "extension": ".jpg",
      "image": "10 x 8 - Golden Sunset - 1986-10-22.jpg"
  },
  {
      "season": 10,
      "episode": 9,
      "link": "https://youtu.be/Qj6lMtnCt8o?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Oval",
      "date": "1986-10-29",
      "extension": ".jpg",
      "image": "10 x 9 - Mountain Oval - 1986-10-29.jpg"
  },
  {
      "season": 10,
      "episode": 10,
      "link": "https://youtu.be/OJ_xqtvZf3o?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Ocean Sunset",
      "date": "1986-11-05",
      "extension": ".jpg",
      "image": "10 x 10 - Ocean Sunset - 1986-11-05.jpg"
  },
  {
      "season": 10,
      "episode": 11,
      "link": "https://youtu.be/zoTeyliLn5o?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Triple View",
      "date": "1986-11-12",
      "extension": ".jpg",
      "image": "10 x 11 - Triple View - 1986-11-12.jpg"
  },
  {
      "season": 10,
      "episode": 12,
      "link": "https://youtu.be/8satX-hLkuI?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "br"
      ],
      "name": "Winter Frost",
      "date": "1986-11-19",
      "extension": ".jpg",
      "image": "10 x 12 - Winter Frost - 1986-11-19.jpg"
  },
  {
      "season": 10,
      "episode": 13,
      "link": "https://youtu.be/1l_TsfCIb_I?list=PLAEQD0ULngi5nVGjPmjw-vCE5AuDTLkkQ",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lakeside Cabin",
      "date": "1986-11-26",
      "extension": ".jpg",
      "image": "10 x 13 - Lakeside Cabin - 1986-11-26.jpg"
  },
  {
      "season": 11,
      "episode": 1,
      "link": "https://youtu.be/xdFCj6BzQio?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Stream",
      "date": "1986-12-30",
      "extension": ".jpg",
      "image": "11 x 1 - Mountain Stream - 1986-12-30.jpg"
  },
  {
      "season": 11,
      "episode": 2,
      "link": "https://youtu.be/Dkww2nHpuZw?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Country Cabin",
      "date": "1986-01-07",
      "extension": ".jpg",
      "image": "11 x 2 - Country Cabin - 1986-01-07.jpg"
  },
  {
      "season": 11,
      "episode": 3,
      "link": "https://youtu.be/HuHC1RqtvDA?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo"
      ],
      "name": "Daisy Delight",
      "date": "1986-01-14",
      "extension": ".jpg",
      "image": "11 x 3 - Daisy Delight - 1986-01-14.jpg"
  },
  {
      "season": 11,
      "episode": 4,
      "link": "https://youtu.be/JGzTg8fCj4w?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Hidden Stream",
      "date": "1986-01-21",
      "extension": ".jpg",
      "image": "11 x 4 - Hidden Stream - 1986-01-21.jpg"
  },
  {
      "season": 11,
      "episode": 5,
      "link": "https://youtu.be/67vdGbA3Xkg?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Towering Glacier",
      "date": "1986-01-28",
      "extension": ".jpg",
      "image": "11 x 5 - Towering Glacier - 1986-01-28.jpg"
  },
  {
      "season": 11,
      "episode": 6,
      "link": "https://youtu.be/HqBhCibidNM?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "br"
      ],
      "name": "Oval Barn",
      "date": "1986-02-04",
      "extension": ".jpg",
      "image": "11 x 6 - Oval Barn - 1986-02-04.jpg"
  },
  {
      "season": 11,
      "episode": 7,
      "link": "https://youtu.be/1yjGoJokbZg?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lakeside Path",
      "date": "1986-02-11",
      "extension": ".jpg",
      "image": "11 x 7 - Lakeside Path - 1986-02-11.jpg"
  },
  {
      "season": 11,
      "episode": 8,
      "link": "https://youtu.be/9xG6IzcGotI?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Sunset Oval",
      "date": "1986-02-18",
      "extension": ".jpg",
      "image": "11 x 8 - Sunset Oval - 1986-02-18.jpg"
  },
  {
      "season": 11,
      "episode": 9,
      "link": "https://youtu.be/_xkn0ceDreo?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Winter Barn",
      "date": "1986-02-25",
      "extension": ".jpg",
      "image": "11 x 9 - Winter Barn - 1986-02-25.jpg"
  },
  {
      "season": 11,
      "episode": 10,
      "link": "https://youtu.be/c4b_B2F1eZg?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "iy",
          "br"
      ],
      "name": "Sunset over the Waves",
      "date": "1986-03-04",
      "extension": ".jpg",
      "image": "11 x 10 - Sunset over the Waves - 1986-03-04.jpg"
  },
  {
      "season": 11,
      "episode": 11,
      "link": "https://youtu.be/aA8RhtaWACA?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "prb",
          "ds",
          "vdb",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Golden Glow",
      "date": "1986-03-11",
      "extension": ".jpg",
      "image": "11 x 11 - Golden Glow - 1986-03-11.jpg"
  },
  {
      "season": 11,
      "episode": 12,
      "link": "https://youtu.be/vJpKhiXvXdA?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Roadside Barn",
      "date": "1986-03-18",
      "extension": ".jpg",
      "image": "11 x 12 - Roadside Barn - 1986-03-18.jpg"
  },
  {
      "season": 11,
      "episode": 13,
      "link": "https://youtu.be/Fw6odlNp7_8?list=PLAEQD0ULngi7aIB4ifBbRHlAmsWpDko5M",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Happy Accident",
      "date": "1986-03-25",
      "extension": ".jpg",
      "image": "11 x 13 - Happy Accident - 1986-03-25.jpg"
  },
  {
      "season": 12,
      "episode": 1,
      "link": "https://youtu.be/-O0Bs65eN5w?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "mb",
          "ds",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Golden Knoll",
      "date": "1987-04-29",
      "extension": ".jpg",
      "image": "12 x 1 - Golden Knoll - 1987-04-29.jpg"
  },
  {
      "season": 12,
      "episode": 2,
      "link": "https://youtu.be/rzYpa4XGSnA?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy",
          "br"
      ],
      "name": "Mountain Reflections",
      "date": "1987-05-06",
      "extension": ".jpg",
      "image": "12 x 2 - Mountain Reflections - 1987-05-06.jpg"
  },
  {
      "season": 12,
      "episode": 3,
      "link": "https://youtu.be/3PZabdohLso?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Secluded Mountain",
      "date": "1987-05-13",
      "extension": ".jpg",
      "image": "12 x 3 - Secluded Mountain - 1987-05-13.jpg"
  },
  {
      "season": 12,
      "episode": 4,
      "link": "https://youtu.be/aR-C3h5zURM?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Bright Autumn Trees",
      "date": "1987-05-20",
      "extension": ".jpg",
      "image": "12 x 4 - Bright Autumn Trees - 1987-05-20.jpg"
  },
  {
      "season": 12,
      "episode": 5,
      "link": "https://youtu.be/P_aCan4cBwc?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "cy",
          "br"
      ],
      "name": "Black Seascape",
      "date": "1987-05-27",
      "extension": ".jpg",
      "image": "12 x 5 - Black Seascape - 1987-05-27.jpg"
  },
  {
      "season": 12,
      "episode": 6,
      "link": "https://youtu.be/fRK8_ioYWw4?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Steep Mountains",
      "date": "1987-06-03",
      "extension": ".jpg",
      "image": "12 x 6 - Steep Mountains - 1987-06-03.jpg"
  },
  {
      "season": 12,
      "episode": 7,
      "link": "https://youtu.be/Leiw-FtADZc?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Quiet Mountains River",
      "date": "1987-06-10",
      "extension": ".jpg",
      "image": "12 x 7 - Quiet Mountains River - 1987-06-10.jpg"
  },
  {
      "season": 12,
      "episode": 8,
      "link": "https://youtu.be/PutvF_P4588?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Evening Waterfall",
      "date": "1987-06-17",
      "extension": ".jpg",
      "image": "12 x 8 - Evening Waterfall - 1987-06-17.jpg"
  },
  {
      "season": 12,
      "episode": 9,
      "link": "https://youtu.be/RrBsbqO9aqI?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Tropical Seascape",
      "date": "1987-06-24",
      "extension": ".jpg",
      "image": "12 x 9 - Tropical Seascape - 1987-06-24.jpg"
  },
  {
      "season": 12,
      "episode": 10,
      "link": "https://youtu.be/HCsCatvigtw?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain at Sunset",
      "date": "1987-07-01",
      "extension": ".jpg",
      "image": "12 x 10 - Mountain at Sunset - 1987-07-01.jpg"
  },
  {
      "season": 12,
      "episode": 11,
      "link": "https://youtu.be/gOGJYHWjXgE?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Soft Mountain Glow",
      "date": "1987-07-08",
      "extension": ".jpg",
      "image": "12 x 11 - Soft Mountain Glow - 1987-07-08.jpg"
  },
  {
      "season": 12,
      "episode": 12,
      "link": "https://youtu.be/1jRPshs27H8?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain in an Oval",
      "date": "1987-07-15",
      "extension": ".jpg",
      "image": "12 x 12 - Mountain in an Oval - 1987-07-15.jpg"
  },
  {
      "season": 12,
      "episode": 13,
      "link": "https://youtu.be/46vI20697HI?list=PLAEQD0ULngi5d5K57ImROanUMpcF-HXIS",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "vdb",
          "ac",
          "br"
      ],
      "name": "Winter Mountain",
      "date": "1987-07-22",
      "extension": ".jpg",
      "image": "12 x 13 - Winter Mountain - 1987-07-22.jpg"
  },
  {
      "season": 13,
      "episode": 1,
      "link": "https://youtu.be/H4GyGrT7lEQ?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Rolling Hills",
      "date": "1987-09-02",
      "extension": ".jpg",
      "image": "13 x 1 - Rolling Hills - 1987-09-02.jpg"
  },
  {
      "season": 13,
      "episode": 2,
      "link": "https://youtu.be/kNZssD9zWlw?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb"
      ],
      "name": "Frozen Solitude",
      "date": "1987-09-09",
      "extension": ".jpg",
      "image": "13 x 2 - Frozen Solitude - 1987-09-09.jpg"
  },
  {
      "season": 13,
      "episode": 3,
      "link": "https://youtu.be/06W8GsWj2Yg?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pb",
          "pg",
          "prb",
          "mb",
          "ds",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Meadow Brook",
      "date": "1987-09-16",
      "extension": ".jpg",
      "image": "13 x 3 - Meadow Brook - 1987-09-16.jpg"
  },
  {
      "season": 13,
      "episode": 4,
      "link": "https://youtu.be/7ZUQTXuDPaI?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Evening at Sunset",
      "date": "1987-09-23",
      "extension": ".jpg",
      "image": "13 x 4 - Evening at Sunset - 1987-09-23.jpg"
  },
  {
      "season": 13,
      "episode": 5,
      "link": "https://youtu.be/dJfnAyDLwPY?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain View",
      "date": "1987-09-30",
      "extension": ".jpg",
      "image": "13 x 5 - Mountain View - 1987-09-30.jpg"
  },
  {
      "season": 13,
      "episode": 6,
      "link": "https://youtu.be/KmoRz01bm0Y?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Hidden Creek",
      "date": "1987-10-07",
      "extension": ".jpg",
      "image": "13 x 6 - Hidden Creek - 1987-10-07.jpg"
  },
  {
      "season": 13,
      "episode": 7,
      "link": "https://youtu.be/j20pZ96E_CE?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Peaceful Haven",
      "date": "1987-10-14",
      "extension": ".jpg",
      "image": "13 x 7 - Peaceful Haven - 1987-10-14.jpg"
  },
  {
      "season": 13,
      "episode": 8,
      "link": "https://youtu.be/bgI3_1quJ18?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Mountain Exhibition",
      "date": "1987-10-21",
      "extension": ".jpg",
      "image": "13 x 8 - Mountain Exhibition - 1987-10-21.jpg"
  },
  {
      "season": 13,
      "episode": 9,
      "link": "https://youtu.be/lLunPQBzW6g?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "sg",
          "cy",
          "yo"
      ],
      "name": "Emerald Waters",
      "date": "1987-10-28",
      "extension": ".jpg",
      "image": "13 x 9 - Emerald Waters - 1987-10-28.jpg"
  },
  {
      "season": 13,
      "episode": 10,
      "link": "https://youtu.be/kasGRkfkiPM?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Summit",
      "date": "1987-11-04",
      "extension": ".jpg",
      "image": "13 x 10 - Mountain Summit - 1987-11-04.jpg"
  },
  {
      "season": 13,
      "episode": 11,
      "link": "https://youtu.be/NjTlW2NL1Lo?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Cabin Hideaway",
      "date": "1987-11-11",
      "extension": ".jpg",
      "image": "13 x 11 - Cabin Hideaway - 1987-11-11.jpg"
  },
  {
      "season": 13,
      "episode": 12,
      "link": "https://youtu.be/q7WXF_BSEXw?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "nmw",
          "ac",
          "cy",
          "mgb"
      ],
      "name": "Oval Essence",
      "date": "1987-11-18",
      "extension": ".jpg",
      "image": "13 x 12 - Oval Essence - 1987-11-18.jpg"
  },
  {
      "season": 13,
      "episode": 13,
      "link": "https://youtu.be/9-ATP8xyDM0?list=PLAEQD0ULngi5rdYozRhnJIOp5pWHCsPn2",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lost Lake",
      "date": "1987-11-25",
      "extension": ".jpg",
      "image": "13 x 13 - Lost Lake - 1987-11-25.jpg"
  },
  {
      "season": 14,
      "episode": 1,
      "link": "https://youtu.be/GpA9UM7QGag?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Distant Mountains",
      "date": "1987-12-30",
      "extension": ".jpg",
      "image": "14 x 1 - Distant Mountains - 1987-12-30.jpg"
  },
  {
      "season": 14,
      "episode": 2,
      "link": "https://youtu.be/Ov5oIHTAa10?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Meadow Brook Surprise",
      "date": "1988-01-06",
      "extension": ".jpg",
      "image": "14 x 2 - Meadow Brook Surprise - 1988-01-06.jpg"
  },
  {
      "season": 14,
      "episode": 3,
      "link": "https://youtu.be/CigXQtT6BPM?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pb",
          "prb",
          "nmw",
          "mgb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Mountain Moonlight Oval",
      "date": "1988-01-13",
      "extension": ".jpg",
      "image": "14 x 3 - Mountain Moonlight Oval - 1988-01-13.jpg"
  },
  {
      "season": 14,
      "episode": 4,
      "link": "https://youtu.be/1ZriQGhSFTM?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "yo"
      ],
      "name": "Snowy Solitude",
      "date": "1988-01-20",
      "extension": ".jpg",
      "image": "14 x 4 - Snowy Solitude - 1988-01-20.jpg"
  },
  {
      "season": 14,
      "episode": 5,
      "link": "https://youtu.be/CnMLKAGi0yM?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain River",
      "date": "1988-01-27",
      "extension": ".jpg",
      "image": "14 x 5 - Mountain River - 1988-01-27.jpg"
  },
  {
      "season": 14,
      "episode": 6,
      "link": "https://youtu.be/0uVe8T-vVVg?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Graceful Mountains",
      "date": "1988-02-03",
      "extension": ".jpg",
      "image": "14 x 6 - Graceful Mountains - 1988-02-03.jpg"
  },
  {
      "season": 14,
      "episode": 7,
      "link": "https://youtu.be/y6GVVjG4HMg?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pg",
          "nmw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Windy Waves",
      "date": "1988-02-10",
      "extension": ".jpg",
      "image": "14 x 7 - Windy Waves - 1988-02-10.jpg"
  },
  {
      "season": 14,
      "episode": 8,
      "link": "https://youtu.be/e63Cgln6Yag?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "On a Clear Day",
      "date": "1988-02-17",
      "extension": ".jpg",
      "image": "14 x 8 - On a Clear Day - 1988-02-17.jpg"
  },
  {
      "season": 14,
      "episode": 9,
      "link": "https://youtu.be/d83REwSGfGo?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Riverside Escape Oval",
      "date": "1988-02-24",
      "extension": ".jpg",
      "image": "14 x 9 - Riverside Escape Oval - 1988-02-24.jpg"
  },
  {
      "season": 14,
      "episode": 10,
      "link": "https://youtu.be/QbclL1ca7_s?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Surprising Falls",
      "date": "1988-03-02",
      "extension": ".jpg",
      "image": "14 x 10 - Surprising Falls - 1988-03-02.jpg"
  },
  {
      "season": 14,
      "episode": 11,
      "link": "https://youtu.be/BQWJ3kqonpA?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "mgb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Shadow Pond",
      "date": "1988-03-09",
      "extension": ".jpg",
      "image": "14 x 11 - Shadow Pond - 1988-03-09.jpg"
  },
  {
      "season": 14,
      "episode": 12,
      "link": "https://youtu.be/ddU9vQvSpw8?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Misty Forest Oval",
      "date": "1988-03-16",
      "extension": ".jpg",
      "image": "14 x 12 - Misty Forest Oval - 1988-03-16.jpg"
  },
  {
      "season": 14,
      "episode": 13,
      "link": "https://youtu.be/knOutsaiKfU?list=PLAEQD0ULngi4tDLpPnT7XV0hzcIKMZLlP",
      "colors": [
          "tw",
          "prb",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Natural Wonder",
      "date": "1988-03-23",
      "extension": ".jpg",
      "image": "14 x 13 - Natural Wonder - 1988-03-23.jpg"
  },
  {
      "season": 15,
      "episode": 1,
      "link": "https://youtu.be/oJvk7gPDHiE?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Splendor of Winter",
      "date": "1988-04-27",
      "extension": ".jpg",
      "image": "15 x 1 - Splendor of Winter - 1988-04-27.jpg"
  },
  {
      "season": 15,
      "episode": 2,
      "link": "https://youtu.be/Me9Ietooq4w?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Colors of Nature",
      "date": "1988-05-04",
      "extension": ".jpg",
      "image": "15 x 2 - Colors of Nature - 1988-05-04.jpg"
  },
  {
      "season": 15,
      "episode": 3,
      "link": "https://youtu.be/IPPU49PyfEA?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Grandpa's Barn",
      "date": "1988-05-11",
      "extension": ".jpg",
      "image": "15 x 3 - Grandpa's Barn - 1988-05-11.jpg"
  },
  {
      "season": 15,
      "episode": 4,
      "link": "https://youtu.be/gYuH4Ilqdhs?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Peaceful Reflections",
      "date": "1988-05-18",
      "extension": ".jpg",
      "image": "15 x 4 - Peaceful Reflections - 1988-05-18.jpg"
  },
  {
      "season": 15,
      "episode": 5,
      "link": "https://youtu.be/yxAMOdl6RJE?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Hidden Winter Moon Oval",
      "date": "1988-05-25",
      "extension": ".jpg",
      "image": "15 x 5 - Hidden Winter Moon Oval - 1988-05-25.jpg"
  },
  {
      "season": 15,
      "episode": 6,
      "link": "https://youtu.be/tayQX_ng-Nc?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Waves of Wonder",
      "date": "1988-06-01",
      "extension": ".jpg",
      "image": "15 x 6 - Waves of Wonder - 1988-06-01.jpg"
  },
  {
      "season": 15,
      "episode": 7,
      "link": "https://youtu.be/TyOO6WeP2AY?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Cabin by the Pond",
      "date": "1988-06-08",
      "extension": ".jpg",
      "image": "15 x 7 - Cabin by the Pond - 1988-06-08.jpg"
  },
  {
      "season": 15,
      "episode": 8,
      "link": "https://youtu.be/ZY0ofhdV_L0?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pb",
          "mb",
          "prb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Fall Stream",
      "date": "1988-06-15",
      "extension": ".jpg",
      "image": "15 x 8 - Fall Stream - 1988-06-15.jpg"
  },
  {
      "season": 15,
      "episode": 9,
      "link": "https://youtu.be/cVqFG2pxK2A?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "nmw",
          "mgb",
          "ds",
          "vdb",
          "ac",
          "cy"
      ],
      "name": "Spring Time Mountain Lake",
      "date": "1988-06-22",
      "extension": ".jpg",
      "image": "15 x 9 - Spring Time Mountain Lake - 1988-06-22.jpg"
  },
  {
      "season": 15,
      "episode": 10,
      "link": "https://youtu.be/COsJUfPN2dA?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pb",
          "mb",
          "nmw",
          "mgb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Forest Down Oval",
      "date": "1988-06-29",
      "extension": ".jpg",
      "image": "15 x 10 - Forest Down Oval - 1988-06-29.jpg"
  },
  {
      "season": 15,
      "episode": 11,
      "link": "https://youtu.be/Hg5RKc6xiL4?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Pathway to Autumn",
      "date": "1988-07-06",
      "extension": ".jpg",
      "image": "15 x 11 - Pathway to Autumn - 1988-07-06.jpg"
  },
  {
      "season": 15,
      "episode": 12,
      "link": "https://youtu.be/2bjFmSQjQrw?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "mgb",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Deep Forest Lake",
      "date": "1988-07-13",
      "extension": ".jpg",
      "image": "15 x 12 - Deep Forest Lake - 1988-07-13.jpg"
  },
  {
      "season": 15,
      "episode": 13,
      "link": "https://youtu.be/lTb8DN6G6dE?list=PLAEQD0ULngi78t8tWZw6zPofUY5_Tz6AU",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Christmas Eve Snow",
      "date": "1988-07-20",
      "extension": ".jpg",
      "image": "15 x 13 - Christmas Eve Snow - 1988-07-20.jpg"
  },
  {
      "season": 16,
      "episode": 1,
      "link": "https://youtu.be/jwVz0uTLH1I?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Two Seasons",
      "date": "1988-07-17",
      "extension": ".jpg",
      "image": "16 x 1 - Two Seasons - 1988-07-17.jpg"
  },
  {
      "season": 16,
      "episode": 2,
      "link": "https://youtu.be/l_jN9KN257M?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Nestled Cabin",
      "date": "1988-07-24",
      "extension": ".jpg",
      "image": "16 x 2 - Nestled Cabin - 1988-07-24.jpg"
  },
  {
      "season": 16,
      "episode": 3,
      "link": "https://youtu.be/uZyZW3tkCE0?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Wintertime Discovery",
      "date": "1988-07-31",
      "extension": ".jpg",
      "image": "16 x 3 - Wintertime Discovery - 1988-07-31.jpg"
  },
  {
      "season": 16,
      "episode": 4,
      "link": "https://youtu.be/jfCsew_mz7A?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "mgb",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Mirage Wood Shape",
      "date": "1988-08-07",
      "extension": ".jpg",
      "image": "16 x 4 - Mountain Mirage Wood Shape - 1988-08-07.jpg"
  },
  {
      "season": 16,
      "episode": 5,
      "link": "https://youtu.be/4jAsLpJzjHM?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Double Oval Fantasy",
      "date": "1988-08-14",
      "extension": ".jpg",
      "image": "16 x 5 - Double Oval Fantasy - 1988-08-14.jpg"
  },
  {
      "season": 16,
      "episode": 6,
      "link": "https://youtu.be/gnp6WE7Ql-s?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "brown"
      ],
      "name": "Contemplative Lady",
      "date": "1988-08-21",
      "extension": ".jpg",
      "image": "16 x 6 - Contemplative Lady - 1988-08-21.jpg"
  },
  {
      "season": 16,
      "episode": 7,
      "link": "https://youtu.be/rE5ZVs_YJfE?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Deep Woods",
      "date": "1988-08-28",
      "extension": ".jpg",
      "image": "16 x 7 - Deep Woods - 1988-08-28.jpg"
  },
  {
      "season": 16,
      "episode": 8,
      "link": "https://youtu.be/_IREQ4SIcX8?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "High Tide",
      "date": "1988-09-04",
      "extension": ".jpg",
      "image": "16 x 8 - High Tide - 1988-09-04.jpg"
  },
  {
      "season": 16,
      "episode": 9,
      "link": "https://youtu.be/wJmi7-G9r-w?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "yo"
      ],
      "name": "Barn In Snow Oval",
      "date": "1988-09-11",
      "extension": ".jpg",
      "image": "16 x 9 - Barn In Snow Oval - 1988-09-11.jpg"
  },
  {
      "season": 16,
      "episode": 10,
      "link": "https://youtu.be/q5moLoqOkP0?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "tw",
          "pb",
          "ac",
          "br",
          "vdb",
          "mb",
          "iy",
          "cy",
          "sg"
      ],
      "name": "That Time Of Year",
      "date": "1988-09-18",
      "extension": ".jpg",
      "image": "16 x 10 - That Time Of Year - 1988-09-18.jpg"
  },
  {
      "season": 16,
      "episode": 11,
      "link": "https://youtu.be/AGhXEPfp-W4?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "mgw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Waterfall Wonder",
      "date": "1988-09-25",
      "extension": ".jpg",
      "image": "16 x 11 - Waterfall Wonder - 1988-09-25.jpg"
  },
  {
      "season": 16,
      "episode": 12,
      "link": "https://youtu.be/4vXB2R8ybDE?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mighty Mountain Lake",
      "date": "1988-10-02",
      "extension": ".jpg",
      "image": "16 x 12 - Mighty Mountain Lake - 1988-10-02.jpg"
  },
  {
      "season": 16,
      "episode": 13,
      "link": "https://youtu.be/-XA2h17y3HU?list=PLAEQD0ULngi759CWjXUQM4IUE0fCn-REo",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Wooded Stream Oval",
      "date": "1988-10-09",
      "extension": ".jpg",
      "image": "16 x 13 - Wooded Stream Oval - 1988-10-09.jpg"
  },
  {
      "season": 17,
      "episode": 1,
      "link": "https://youtu.be/lhpfaW0k6uM?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Golden Mist Oval",
      "date": "1989-01-04",
      "extension": ".jpg",
      "image": "17 x 1 - Golden Mist Oval - 1989-01-04.jpg"
  },
  {
      "season": 17,
      "episode": 2,
      "link": "https://youtu.be/EVQcDEiJh2o?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "The Old Home Place",
      "date": "1989-01-11",
      "extension": ".jpg",
      "image": "17 x 2 - The Old Home Place - 1989-01-11.jpg"
  },
  {
      "season": 17,
      "episode": 3,
      "link": "https://youtu.be/NqfRLiv0SZ0?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mgb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Soothing Vista",
      "date": "1989-01-18",
      "extension": ".jpg",
      "image": "17 x 3 - Soothing Vista - 1989-01-18.jpg"
  },
  {
      "season": 17,
      "episode": 4,
      "link": "https://youtu.be/LE6agCR5iBw?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Stormy Seas",
      "date": "1989-01-25",
      "extension": ".jpg",
      "image": "17 x 4 - Stormy Seas - 1989-01-25.jpg"
  },
  {
      "season": 17,
      "episode": 5,
      "link": "https://youtu.be/e_bt9rQoGN0?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Country Time",
      "date": "1989-02-01",
      "extension": ".jpg",
      "image": "17 x 5 - Country Time - 1989-02-01.jpg"
  },
  {
      "season": 17,
      "episode": 6,
      "link": "https://youtu.be/BbYO579MmhA?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "A Mild Winter's Day",
      "date": "1989-02-08",
      "extension": ".jpg",
      "image": "17 x 6 - A Mild Winter's Day - 1989-02-08.jpg"
  },
  {
      "season": 17,
      "episode": 7,
      "link": "https://youtu.be/nK-HebXL2uw?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Spectacular Waterfall",
      "date": "1989-02-15",
      "extension": ".jpg",
      "image": "17 x 7 - Spectacular Waterfall - 1989-02-15.jpg"
  },
  {
      "season": 17,
      "episode": 8,
      "link": "https://youtu.be/Q4n90jsOUtY?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "View From The Park",
      "date": "1989-02-22",
      "extension": ".jpg",
      "image": "17 x 8 - View From The Park - 1989-02-22.jpg"
  },
  {
      "season": 17,
      "episode": 9,
      "link": "https://youtu.be/07acfzBaoa0?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lake View",
      "date": "1989-03-01",
      "extension": ".jpg",
      "image": "17 x 9 - Lake View - 1989-03-01.jpg"
  },
  {
      "season": 17,
      "episode": 10,
      "link": "https://youtu.be/n8HlFCQACYA?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Old Country Mill",
      "date": "1989-03-08",
      "extension": ".jpg",
      "image": "17 x 10 - Old Country Mill - 1989-03-08.jpg"
  },
  {
      "season": 17,
      "episode": 11,
      "link": "https://youtu.be/pSvgNkQdR2Y?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Morning Walk",
      "date": "1989-03-15",
      "extension": ".jpg",
      "image": "17 x 11 - Morning Walk - 1989-03-15.jpg"
  },
  {
      "season": 17,
      "episode": 12,
      "link": "https://youtu.be/uCAtI2IwEwk?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mgb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Nature's Splendor",
      "date": "1989-03-22",
      "extension": ".jpg",
      "image": "17 x 12 - Nature's Splendor - 1989-03-22.jpg"
  },
  {
      "season": 17,
      "episode": 13,
      "link": "https://youtu.be/8P-YeoTmVrw?list=PLAEQD0ULngi5jejZ_dD7KhhEoLbjmglar",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mgb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Beauty",
      "date": "1989-03-29",
      "extension": ".jpg",
      "image": "17 x 13 - Mountain Beauty - 1989-03-29.jpg"
  },
  {
      "season": 18,
      "episode": 1,
      "link": "https://youtu.be/uY3fIry2tOE?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Half-Oval Vignette",
      "date": "1989-07-05",
      "extension": ".jpg",
      "image": "18 x 1 - Half-Oval Vignette - 1989-07-05.jpg"
  },
  {
      "season": 18,
      "episode": 2,
      "link": "https://youtu.be/PGPVpil2UmE?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Absolutely Autumn",
      "date": "1989-07-12",
      "extension": ".jpg",
      "image": "18 x 2 - Absolutely Autumn - 1989-07-12.jpg"
  },
  {
      "season": 18,
      "episode": 3,
      "link": "https://youtu.be/EBZKuVbRY54?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "nmw",
          "mgb",
          "vdb",
          "ac",
          "cy"
      ],
      "name": "Mountain Seclusion",
      "date": "1989-07-19",
      "extension": ".jpg",
      "image": "18 x 3 - Mountain Seclusion - 1989-07-19.jpg"
  },
  {
      "season": 18,
      "episode": 4,
      "link": "https://youtu.be/R7Y3izMFPbM?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Crimson Oval",
      "date": "1989-07-26",
      "extension": ".jpg",
      "image": "18 x 4 - Crimson Oval - 1989-07-26.jpg"
  },
  {
      "season": 18,
      "episode": 5,
      "link": "https://youtu.be/6afHY2d9Lv8?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Autumn Exhibition",
      "date": "1989-08-02",
      "extension": ".jpg",
      "image": "18 x 5 - Autumn Exhibition - 1989-08-02.jpg"
  },
  {
      "season": 18,
      "episode": 6,
      "link": "https://youtu.be/lilbzLCNnDo?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Majestic Peaks",
      "date": "1989-08-09",
      "extension": ".jpg",
      "image": "18 x 6 - Majestic Peaks - 1989-08-09.jpg"
  },
  {
      "season": 18,
      "episode": 7,
      "link": "https://youtu.be/rCHXqj4DHlM?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Golden Morning Mist",
      "date": "1989-08-16",
      "extension": ".jpg",
      "image": "18 x 7 - Golden Morning Mist - 1989-08-16.jpg"
  },
  {
      "season": 18,
      "episode": 8,
      "link": "https://youtu.be/WJF_qoQRPck?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "prb",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "br"
      ],
      "name": "Winter Lace",
      "date": "1989-08-23",
      "extension": ".jpg",
      "image": "18 x 8 - Winter Lace - 1989-08-23.jpg"
  },
  {
      "season": 18,
      "episode": 9,
      "link": "https://youtu.be/sBBBilrDuSw?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Seascape Fantasy",
      "date": "1989-08-30",
      "extension": ".jpg",
      "image": "18 x 9 - Seascape Fantasy - 1989-08-30.jpg"
  },
  {
      "season": 18,
      "episode": 10,
      "link": "https://youtu.be/rRjnHdr9DmU?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "mgb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Double Oval Stream",
      "date": "1989-09-06",
      "extension": ".jpg",
      "image": "18 x 10 - Double Oval Stream - 1989-09-06.jpg"
  },
  {
      "season": 18,
      "episode": 11,
      "link": "https://youtu.be/ikR7UT9mVBw?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "mgb",
          "nmw",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Enchanted Forest",
      "date": "1989-09-13",
      "extension": ".jpg",
      "image": "18 x 11 - Enchanted Forest - 1989-09-13.jpg"
  },
  {
      "season": 18,
      "episode": 12,
      "link": "https://youtu.be/EVfPPJ5FUmA?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Southwest Serenity",
      "date": "1989-09-20",
      "extension": ".jpg",
      "image": "18 x 12 - Southwest Serenity - 1989-09-20.jpg"
  },
  {
      "season": 18,
      "episode": 13,
      "link": "https://youtu.be/XvnJBynSiT0?list=PLAEQD0ULngi79FbgDR5HQURtzgXlRUfYa",
      "colors": [
          "tw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Rippling Waters",
      "date": "1989-09-27",
      "extension": ".jpg",
      "image": "18 x 13 - Rippling Waters - 1989-09-27.jpg"
  },
  {
      "season": 19,
      "episode": 1,
      "link": "https://youtu.be/1enWTsRi16o?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Snowfall Magic",
      "date": "1990-01-03",
      "extension": ".jpg",
      "image": "19 x 1 - Snowfall Magic - 1990-01-03.jpg"
  },
  {
      "season": 19,
      "episode": 2,
      "link": "https://youtu.be/2gq2V6dCt2I?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Quiet Mountain Lake",
      "date": "1990-01-10",
      "extension": ".jpg",
      "image": "19 x 2 - Quiet Mountain Lake - 1990-01-10.jpg"
  },
  {
      "season": 19,
      "episode": 3,
      "link": "https://youtu.be/IeFbdk36MUU?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Final Embers of Sunlight",
      "date": "1990-01-17",
      "extension": ".jpg",
      "image": "19 x 3 - Final Embers of Sunlight - 1990-01-17.jpg"
  },
  {
      "season": 19,
      "episode": 4,
      "link": "https://youtu.be/iB1TDWlo0kk?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "yo"
      ],
      "name": "Snowy Morn",
      "date": "1990-01-24",
      "extension": ".jpg",
      "image": "19 x 4 - Snowy Morn - 1990-01-24.jpg"
  },
  {
      "season": 19,
      "episode": 5,
      "link": "https://youtu.be/C34WAUgkAT0?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Camper's Haven",
      "date": "1990-01-31",
      "extension": ".jpg",
      "image": "19 x 5 - Camper's Haven - 1990-01-31.jpg"
  },
  {
      "season": 19,
      "episode": 6,
      "link": "https://youtu.be/uBRKVa7sy1Q?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Waterfall in the Woods",
      "date": "1990-02-07",
      "extension": ".jpg",
      "image": "19 x 6 - Waterfall in the Woods - 1990-02-07.jpg"
  },
  {
      "season": 19,
      "episode": 7,
      "link": "https://youtu.be/F4iPbH1OHsQ?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Covered Bridge Oval",
      "date": "1990-02-14",
      "extension": ".jpg",
      "image": "19 x 7 - Covered Bridge Oval - 1990-02-14.jpg"
  },
  {
      "season": 19,
      "episode": 8,
      "link": "https://youtu.be/d50HkqB9JEM?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Scenic Seclusion",
      "date": "1990-02-21",
      "extension": ".jpg",
      "image": "19 x 8 - Scenic Seclusion - 1990-02-21.jpg"
  },
  {
      "season": 19,
      "episode": 9,
      "link": "https://youtu.be/mFOna_LN7Ys?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Ebb Tide",
      "date": "1990-02-28",
      "extension": ".jpg",
      "image": "19 x 9 - Ebb Tide - 1990-02-28.jpg"
  },
  {
      "season": 19,
      "episode": 10,
      "link": "https://youtu.be/Wj-3ct7RvAI?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "mgb",
          "nmw",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "After the Rain",
      "date": "1990-03-07",
      "extension": ".jpg",
      "image": "19 x 10 - After the Rain - 1990-03-07.jpg"
  },
  {
      "season": 19,
      "episode": 11,
      "link": "https://youtu.be/JjBZzKp_u5E?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "Winter Elegance",
      "date": "1990-03-14",
      "extension": ".jpg",
      "image": "19 x 11 - Winter Elegance - 1990-03-14.jpg"
  },
  {
      "season": 19,
      "episode": 12,
      "link": "https://youtu.be/uEUMVwc4o5U?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy"
      ],
      "name": "Evening's Peace",
      "date": "1990-03-21",
      "extension": ".jpg",
      "image": "19 x 12 - Evening's Peace - 1990-03-21.jpg"
  },
  {
      "season": 19,
      "episode": 13,
      "link": "https://youtu.be/FnY7jDY5PsE?list=PLAEQD0ULngi56cKK-XkgZPwVLZ0lI15Mn",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Valley of Tranquility",
      "date": "1990-03-28",
      "extension": ".jpg",
      "image": "19 x 13 - Valley of Tranquility - 1990-03-28.jpg"
  },
  {
      "season": 20,
      "episode": 1,
      "link": "https://youtu.be/VlucWfTUo1A?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mystic Mountain",
      "date": "1990-04-04",
      "extension": ".jpg",
      "image": "20 x 1 - Mystic Mountain - 1990-04-04.jpg"
  },
  {
      "season": 20,
      "episode": 2,
      "link": "https://youtu.be/vbPdQ0w8ylg?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "New Day's Dawn",
      "date": "1990-04-11",
      "extension": ".jpg",
      "image": "20 x 2 - New Day's Dawn - 1990-04-11.jpg"
  },
  {
      "season": 20,
      "episode": 3,
      "link": "https://youtu.be/kbnXZRNMouM?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "yo",
          "iy",
          "br"
      ],
      "name": "Winter in Pastel",
      "date": "1990-04-18",
      "extension": ".jpg",
      "image": "20 x 3 - Winter in Pastel - 1990-04-18.jpg"
  },
  {
      "season": 20,
      "episode": 4,
      "link": "https://youtu.be/5U3G61r35Mc?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Hazy Day",
      "date": "1990-04-25",
      "extension": ".jpg",
      "image": "20 x 4 - Hazy Day - 1990-04-25.jpg"
  },
  {
      "season": 20,
      "episode": 5,
      "link": "https://youtu.be/mb-Gwx1S5Gs?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Divine Elegance",
      "date": "1990-05-02",
      "extension": ".jpg",
      "image": "20 x 5 - Divine Elegance - 1990-05-02.jpg"
  },
  {
      "season": 20,
      "episode": 6,
      "link": "https://youtu.be/p6Uy2qOLvGk?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pb",
          "mb",
          "mgb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Cliffside",
      "date": "1990-05-09",
      "extension": ".jpg",
      "image": "20 x 6 - Cliffside - 1990-05-09.jpg"
  },
  {
      "season": 20,
      "episode": 7,
      "link": "https://youtu.be/FozIp7Va7dY?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Autumn Fantasy",
      "date": "1990-05-16",
      "extension": ".jpg",
      "image": "20 x 7 - Autumn Fantasy - 1990-05-16.jpg"
  },
  {
      "season": 20,
      "episode": 8,
      "link": "https://youtu.be/dN4HjAn8p5U?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "The Old Oak Tree",
      "date": "1990-05-23",
      "extension": ".jpg",
      "image": "20 x 8 - The Old Oak Tree - 1990-05-23.jpg"
  },
  {
      "season": 20,
      "episode": 9,
      "link": "https://youtu.be/DY1aBv8Z1SQ?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "ac"
      ],
      "name": "Winter Paradise",
      "date": "1990-05-30",
      "extension": ".jpg",
      "image": "20 x 9 - Winter Paradise - 1990-05-30.jpg"
  },
  {
      "season": 20,
      "episode": 10,
      "link": "https://youtu.be/DmYhNHVIdMI?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Days Gone By",
      "date": "1990-06-06",
      "extension": ".jpg",
      "image": "20 x 10 - Days Gone By - 1990-06-06.jpg"
  },
  {
      "season": 20,
      "episode": 11,
      "link": "https://youtu.be/QbgPu5f2Vf8?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Change of Seasons",
      "date": "1990-06-13",
      "extension": ".jpg",
      "image": "20 x 11 - Change of Seasons - 1990-06-13.jpg"
  },
  {
      "season": 20,
      "episode": 12,
      "link": "https://youtu.be/ZyPNzDaaDt8?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Hidden Delight",
      "date": "1990-06-20",
      "extension": ".jpg",
      "image": "20 x 12 - Hidden Delight - 1990-06-20.jpg"
  },
  {
      "season": 20,
      "episode": 13,
      "link": "https://youtu.be/ppzFgG2K2k4?list=PLAEQD0ULngi7-jK4pdhsSiu5CC0ojRqmM",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Double Take",
      "date": "1990-06-27",
      "extension": ".jpg",
      "image": "20 x 13 - Double Take - 1990-06-27.jpg"
  },
  {
      "season": 21,
      "episode": 1,
      "link": "https://youtu.be/pw5ETGiiBRg?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Valley View",
      "date": "1990-09-05",
      "extension": ".jpg",
      "image": "21 x 1 - Valley View - 1990-09-05.jpg"
  },
  {
      "season": 21,
      "episode": 2,
      "link": "https://youtu.be/4abDq6Q-mlM?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Tranquil Dawn",
      "date": "1990-09-12",
      "extension": ".jpg",
      "image": "21 x 2 - Tranquil Dawn - 1990-09-12.jpg"
  },
  {
      "season": 21,
      "episode": 3,
      "link": "https://youtu.be/ubUXBqE6t0U?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Royal Majesty",
      "date": "1990-09-19",
      "extension": ".jpg",
      "image": "21 x 3 - Royal Majesty - 1990-09-19.jpg"
  },
  {
      "season": 21,
      "episode": 4,
      "link": "https://youtu.be/crqmO3qDdco?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Serenity",
      "date": "1990-09-26",
      "extension": ".jpg",
      "image": "21 x 4 - Serenity - 1990-09-26.jpg"
  },
  {
      "season": 21,
      "episode": 5,
      "link": "https://youtu.be/GLkLO4KqTRs?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Cabin at Trails End",
      "date": "1990-10-03",
      "extension": ".jpg",
      "image": "21 x 5 - Cabin at Trails End - 1990-10-03.jpg"
  },
  {
      "season": 21,
      "episode": 6,
      "link": "https://youtu.be/H4VsmKU5T7g?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "yo",
          "iy"
      ],
      "name": "Mountain Rhapsody",
      "date": "1990-10-10",
      "extension": ".jpg",
      "image": "21 x 6 - Mountain Rhapsody - 1990-10-10.jpg"
  },
  {
      "season": 21,
      "episode": 7,
      "link": "https://youtu.be/GWehiacnd1E?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Wilderness Cabin",
      "date": "1990-10-17",
      "extension": ".jpg",
      "image": "21 x 7 - Wilderness Cabin - 1990-10-17.jpg"
  },
  {
      "season": 21,
      "episode": 8,
      "link": "https://youtu.be/8tHHBTK6wwc?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "By The Sea",
      "date": "1990-10-24",
      "extension": ".jpg",
      "image": "21 x 8 - By The Sea - 1990-10-24.jpg"
  },
  {
      "season": 21,
      "episode": 9,
      "link": "https://youtu.be/pfWV8NkotvU?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Indian Summer",
      "date": "1990-10-31",
      "extension": ".jpg",
      "image": "21 x 9 - Indian Summer - 1990-10-31.jpg"
  },
  {
      "season": 21,
      "episode": 10,
      "link": "https://youtu.be/liR0gS0sZY0?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "tw",
          "prb",
          "mb"
      ],
      "name": "Blue Winter",
      "date": "1990-11-07",
      "extension": ".jpg",
      "image": "21 x 10 - Blue Winter - 1990-11-07.jpg"
  },
  {
      "season": 21,
      "episode": 11,
      "link": "https://youtu.be/QyncJfL_HmU?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Desert Glow",
      "date": "1990-11-14",
      "extension": ".jpg",
      "image": "21 x 11 - Desert Glow - 1990-11-14.jpg"
  },
  {
      "season": 21,
      "episode": 12,
      "link": "https://youtu.be/NJbzIftLLmU?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lone Mountain",
      "date": "1990-11-21",
      "extension": ".jpg",
      "image": "21 x 12 - Lone Mountain - 1990-11-21.jpg"
  },
  {
      "season": 21,
      "episode": 13,
      "link": "https://youtu.be/HQC5sPN9Xhc?list=PLAEQD0ULngi5_UcEWkQZu23WzQP1Tkxq3",
      "colors": [
          "tw",
          "pb",
          "mb",
          "prb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo"
      ],
      "name": "Florida's Glory",
      "date": "1990-11-28",
      "extension": ".jpg",
      "image": "21 x 13 - Florida's Glory - 1990-11-28.jpg"
  },
  {
      "season": 22,
      "episode": 1,
      "link": "https://youtu.be/HMx34Am6RFg?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Autumn Images",
      "date": "1991-01-01",
      "extension": ".jpg",
      "image": "22 x 1 - Autumn Images - 1991-01-01.jpg"
  },
  {
      "season": 22,
      "episode": 2,
      "link": "https://youtu.be/C-OSaHvt8ms?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Hint of Springtime",
      "date": "1991-01-08",
      "extension": ".jpg",
      "image": "22 x 2 - Hint of Springtime - 1991-01-08.jpg"
  },
  {
      "season": 22,
      "episode": 3,
      "link": "https://youtu.be/Kl5X3icOVvw?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Around the Bend",
      "date": "1991-01-15",
      "extension": ".jpg",
      "image": "22 x 3 - Around the Bend - 1991-01-15.jpg"
  },
  {
      "season": 22,
      "episode": 4,
      "link": "https://youtu.be/hdNH5axK4u0?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Countryside Oval",
      "date": "1991-01-22",
      "extension": ".jpg",
      "image": "22 x 4 - Countryside Oval - 1991-01-22.jpg"
  },
  {
      "season": 22,
      "episode": 5,
      "link": "https://youtu.be/aE4nID1p-t0?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Russet Winter",
      "date": "1991-01-29",
      "extension": ".jpg",
      "image": "22 x 5 - Russet Winter - 1991-01-29.jpg"
  },
  {
      "season": 22,
      "episode": 6,
      "link": "https://youtu.be/ZoJ2tcXWb7g?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "pb",
          "mgb",
          "mb",
          "nmw",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Purple Haze",
      "date": "1991-02-05",
      "extension": ".jpg",
      "image": "22 x 6 - Purple Haze - 1991-02-05.jpg"
  },
  {
      "season": 22,
      "episode": 7,
      "link": "https://youtu.be/JUuP75RVZDI?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Dimensions",
      "date": "1991-02-12",
      "extension": ".jpg",
      "image": "22 x 7 - Dimensions - 1991-02-12.jpg"
  },
  {
      "season": 22,
      "episode": 8,
      "link": "https://youtu.be/uZWNvDFfbUk?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Deep Wilderness Home",
      "date": "1991-02-19",
      "extension": ".jpg",
      "image": "22 x 8 - Deep Wilderness Home - 1991-02-19.jpg"
  },
  {
      "season": 22,
      "episode": 9,
      "link": "https://youtu.be/Qccgam514ds?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Haven in the Valley",
      "date": "1991-02-26",
      "extension": ".jpg",
      "image": "22 x 9 - Haven in the Valley - 1991-02-26.jpg"
  },
  {
      "season": 22,
      "episode": 10,
      "link": "https://youtu.be/dbMJVM4iL2Q?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Wintertime Blues",
      "date": "1991-03-05",
      "extension": ".jpg",
      "image": "22 x 10 - Wintertime Blues - 1991-03-05.jpg"
  },
  {
      "season": 22,
      "episode": 11,
      "link": "https://youtu.be/K4aud_SpFDQ?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Pastel Seascape",
      "date": "1991-03-12",
      "extension": ".jpg",
      "image": "22 x 11 - Pastel Seascape - 1991-03-12.jpg"
  },
  {
      "season": 22,
      "episode": 12,
      "link": "https://youtu.be/JDa6JPcqy88?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Country Creek",
      "date": "1991-03-19",
      "extension": ".jpg",
      "image": "22 x 12 - Country Creek - 1991-03-19.jpg"
  },
  {
      "season": 22,
      "episode": 13,
      "link": "https://youtu.be/AE1GKcL3NuI?list=PLAEQD0ULngi5b8jcMLQ003OV5C2qUNeFE",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Silent Forest",
      "date": "1991-03-26",
      "extension": ".jpg",
      "image": "22 x 13 - Silent Forest - 1991-03-26.jpg"
  },
  {
      "season": 23,
      "episode": 1,
      "link": "https://youtu.be/1-9_enMBquw?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "vdb",
          "ac",
          "sg",
          "cy"
      ],
      "name": "Frosty Winter Morn",
      "date": "1991-09-03",
      "extension": ".jpg",
      "image": "23 x 1 - Frosty Winter Morn - 1991-09-03.jpg"
  },
  {
      "season": 23,
      "episode": 2,
      "link": "https://youtu.be/puGk2iFvvp0?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "mgb",
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Forest Edge",
      "date": "1991-09-10",
      "extension": ".jpg",
      "image": "23 x 2 - Forest Edge - 1991-09-10.jpg"
  },
  {
      "season": 23,
      "episode": 3,
      "link": "https://youtu.be/Hq-z06i6HCs?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "tw",
          "prb",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Ridge Lake",
      "date": "1991-09-17",
      "extension": ".jpg",
      "image": "23 x 3 - Mountain Ridge Lake - 1991-09-17.jpg"
  },
  {
      "season": 23,
      "episode": 4,
      "link": "https://youtu.be/8f9CCnqJ_1Y?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Reflections of Gold",
      "date": "1991-09-24",
      "extension": ".jpg",
      "image": "23 x 4 - Reflections of Gold - 1991-09-24.jpg"
  },
  {
      "season": 23,
      "episode": 5,
      "link": "https://youtu.be/cR9hTbSomx4?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Quiet Cove",
      "date": "1991-10-01",
      "extension": ".jpg",
      "image": "23 x 5 - Quiet Cove - 1991-10-01.jpg"
  },
  {
      "season": 23,
      "episode": 6,
      "link": "https://youtu.be/flCWS9LB4Ks?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Rivers Peace",
      "date": "1991-10-08",
      "extension": ".jpg",
      "image": "23 x 6 - Rivers Peace - 1991-10-08.jpg"
  },
  {
      "season": 23,
      "episode": 7,
      "link": "https://youtu.be/HFdTxqEznIk?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "br"
      ],
      "name": "At Dawn's Light",
      "date": "1991-10-15",
      "extension": ".jpg",
      "image": "23 x 7 - At Dawn's Light - 1991-10-15.jpg"
  },
  {
      "season": 23,
      "episode": 8,
      "link": "https://youtu.be/VYcsYzzRae4?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Valley Waterfall",
      "date": "1991-10-22",
      "extension": ".jpg",
      "image": "23 x 8 - Valley Waterfall - 1991-10-22.jpg"
  },
  {
      "season": 23,
      "episode": 9,
      "link": "https://youtu.be/cFi_fJl40BA?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Toward Days End",
      "date": "1991-10-29",
      "extension": ".jpg",
      "image": "23 x 9 - Toward Days End - 1991-10-29.jpg"
  },
  {
      "season": 23,
      "episode": 10,
      "link": "https://youtu.be/txAtX-VyVNc?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Falls in the Glen",
      "date": "1991-11-05",
      "extension": ".jpg",
      "image": "23 x 10 - Falls in the Glen - 1991-11-05.jpg"
  },
  {
      "season": 23,
      "episode": 11,
      "link": "https://youtu.be/H15kV1wbDG4?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "yo",
          "br"
      ],
      "name": "Frozen Beauty in Vignette",
      "date": "1991-11-12",
      "extension": ".jpg",
      "image": "23 x 11 - Frozen Beauty in Vignette - 1991-11-12.jpg"
  },
  {
      "season": 23,
      "episode": 12,
      "link": "https://youtu.be/Y7SDU4i6Fco?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo"
      ],
      "name": "Crimson Tide",
      "date": "1991-11-19",
      "extension": ".jpg",
      "image": "23 x 12 - Crimson Tide - 1991-11-19.jpg"
  },
  {
      "season": 23,
      "episode": 13,
      "link": "https://youtu.be/yInYwy6AsDQ?list=PLAEQD0ULngi6dGd30_mzWENbNpT1k844a",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Winter Bliss",
      "date": "1991-11-26",
      "extension": ".jpg",
      "image": "23 x 13 - Winter Bliss - 1991-11-26.jpg"
  },
  {
      "season": 24,
      "episode": 1,
      "link": "https://youtu.be/4XxClvPZ1RE?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Gray Mountain",
      "date": "1992-01-07",
      "extension": ".jpg",
      "image": "24 x 1 - Gray Mountain - 1992-01-07.jpg"
  },
  {
      "season": 24,
      "episode": 2,
      "link": "https://youtu.be/FPW2FwK4IOo?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "yo",
          "br"
      ],
      "name": "Wayside Pond",
      "date": "1992-01-14",
      "extension": ".jpg",
      "image": "24 x 2 - Wayside Pond - 1992-01-14.jpg"
  },
  {
      "season": 24,
      "episode": 3,
      "link": "https://youtu.be/I4h6TC4CPJY?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ac",
          "sg"
      ],
      "name": "Teton Winter",
      "date": "1992-01-21",
      "extension": ".jpg",
      "image": "24 x 3 - Teton Winter - 1992-01-21.jpg"
  },
  {
      "season": 24,
      "episode": 4,
      "link": "https://youtu.be/nU1zV93N-kI?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Little Home in the Meadow",
      "date": "1992-01-28",
      "extension": ".jpg",
      "image": "24 x 4 - Little Home in the Meadow - 1992-01-28.jpg"
  },
  {
      "season": 24,
      "episode": 5,
      "link": "https://youtu.be/HWedDS3p7XI?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "A Pretty Autumn Day",
      "date": "1992-02-04",
      "extension": ".jpg",
      "image": "24 x 5 - A Pretty Autumn Day - 1992-02-04.jpg"
  },
  {
      "season": 24,
      "episode": 6,
      "link": "https://youtu.be/i29frkR1T98?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mirrored Images",
      "date": "1992-02-11",
      "extension": ".jpg",
      "image": "24 x 6 - Mirrored Images - 1992-02-11.jpg"
  },
  {
      "season": 24,
      "episode": 7,
      "link": "https://youtu.be/2iNMoQB1Pe8?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "pb",
          "mgb",
          "prb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Back-Country Path",
      "date": "1992-02-18",
      "extension": ".jpg",
      "image": "24 x 7 - Back-Country Path - 1992-02-18.jpg"
  },
  {
      "season": 24,
      "episode": 8,
      "link": "https://youtu.be/fBh1nA4pMDY?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "pg",
          "mgb",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Graceful Waterfall",
      "date": "1992-02-25",
      "extension": ".jpg",
      "image": "24 x 8 - Graceful Waterfall - 1992-02-25.jpg"
  },
  {
      "season": 24,
      "episode": 9,
      "link": "https://youtu.be/AmBDmgta3l4?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Icy Lake",
      "date": "1992-03-03",
      "extension": ".jpg",
      "image": "24 x 9 - Icy Lake - 1992-03-03.jpg"
  },
  {
      "season": 24,
      "episode": 10,
      "link": "https://youtu.be/hfvhXOvg43w?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "pg",
          "pb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo"
      ],
      "name": "Rowboat on the Beach",
      "date": "1992-03-10",
      "extension": ".jpg",
      "image": "24 x 10 - Rowboat on the Beach - 1992-03-10.jpg"
  },
  {
      "season": 24,
      "episode": 11,
      "link": "https://youtu.be/JLnIUGOupOA?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "mb",
          "ac"
      ],
      "name": "Portrait of Winter",
      "date": "1992-03-17",
      "extension": ".jpg",
      "image": "24 x 11 - Portrait of Winter - 1992-03-17.jpg"
  },
  {
      "season": 24,
      "episode": 12,
      "link": "https://youtu.be/RqtDliGeyTg?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "The Footbridge",
      "date": "1992-03-24",
      "extension": ".jpg",
      "image": "24 x 12 - The Footbridge - 1992-03-24.jpg"
  },
  {
      "season": 24,
      "episode": 13,
      "link": "https://youtu.be/obSLZWXmDak?list=PLAEQD0ULngi5ot5FztonBZegKLnsa1gXC",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ac",
          "sg"
      ],
      "name": "Snowbound Cabin",
      "date": "1992-03-31",
      "extension": ".jpg",
      "image": "24 x 13 - Snowbound Cabin - 1992-03-31.jpg"
  },
  {
      "season": 25,
      "episode": 1,
      "link": "https://youtu.be/l_HqMYquc08?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Hide A Way Cove",
      "date": "1992-08-25",
      "extension": ".jpg",
      "image": "25 x 1 - Hide A Way Cove - 1992-08-25.jpg"
  },
  {
      "season": 25,
      "episode": 2,
      "link": "https://youtu.be/HklPkQ0lSKA?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "mgb",
          "tw",
          "ac",
          "sg",
          "yo",
          "iy"
      ],
      "name": "Enchanted Falls Oval",
      "date": "1992-09-01",
      "extension": ".jpg",
      "image": "25 x 2 - Enchanted Falls Oval - 1992-09-01.jpg"
  },
  {
      "season": 25,
      "episode": 3,
      "link": "https://youtu.be/N_u6x7LeyTM?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "mgb",
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo"
      ],
      "name": "Not Quite Spring",
      "date": "1992-09-08",
      "extension": ".jpg",
      "image": "25 x 3 - Not Quite Spring - 1992-09-08.jpg"
  },
  {
      "season": 25,
      "episode": 4,
      "link": "https://youtu.be/YcbaMqq_X8s?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Splashes Of Autumn",
      "date": "1992-09-15",
      "extension": ".jpg",
      "image": "25 x 4 - Splashes Of Autumn - 1992-09-15.jpg"
  },
  {
      "season": 25,
      "episode": 5,
      "link": "https://youtu.be/gmCXgS38NDc?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Summer In The Mountain",
      "date": "1992-09-22",
      "extension": ".jpg",
      "image": "25 x 5 - Summer In The Mountain - 1992-09-22.jpg"
  },
  {
      "season": 25,
      "episode": 6,
      "link": "https://youtu.be/v8Znj8bbmCQ?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "nmw",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Oriental Falls",
      "date": "1992-09-29",
      "extension": ".jpg",
      "image": "25 x 6 - Oriental Falls - 1992-09-29.jpg"
  },
  {
      "season": 25,
      "episode": 7,
      "link": "https://youtu.be/HWrvW-NgaOY?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "mgb",
          "nmw",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Autumn Palette",
      "date": "1992-10-06",
      "extension": ".jpg",
      "image": "25 x 7 - Autumn Palette - 1992-10-06.jpg"
  },
  {
      "season": 25,
      "episode": 8,
      "link": "https://youtu.be/FdIUHudQABI?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Cypress Swamp",
      "date": "1992-10-13",
      "extension": ".jpg",
      "image": "25 x 8 - Cypress Swamp - 1992-10-13.jpg"
  },
  {
      "season": 25,
      "episode": 9,
      "link": "https://youtu.be/KsDPg5HZYNw?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Downstream View",
      "date": "1992-10-20",
      "extension": ".jpg",
      "image": "25 x 9 - Downstream View - 1992-10-20.jpg"
  },
  {
      "season": 25,
      "episode": 10,
      "link": "https://youtu.be/Rh-jKrMrmOA?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "mgb",
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy"
      ],
      "name": "Just Before The Storm",
      "date": "1992-10-27",
      "extension": ".jpg",
      "image": "25 x 10 - Just Before The Storm - 1992-10-27.jpg"
  },
  {
      "season": 25,
      "episode": 11,
      "link": "https://youtu.be/uJLK85uU5mA?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "mgb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Fisherman's Paradise",
      "date": "1992-11-03",
      "extension": ".jpg",
      "image": "25 x 11 - Fisherman's Paradise - 1992-11-03.jpg"
  },
  {
      "season": 25,
      "episode": 12,
      "link": "https://youtu.be/UUNNXGVNTuI?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Desert Hues",
      "date": "1992-11-10",
      "extension": ".jpg",
      "image": "25 x 12 - Desert Hues - 1992-11-10.jpg"
  },
  {
      "season": 25,
      "episode": 13,
      "link": "https://youtu.be/ivesI3rU7dU?list=PLAEQD0ULngi6XUucZGmcQeVRGHNg2WVsB",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "The Property Line",
      "date": "1992-11-17",
      "extension": ".jpg",
      "image": "25 x 13 - The Property Line - 1992-11-17.jpg"
  },
  {
      "season": 26,
      "episode": 1,
      "link": "https://youtu.be/5rfGa1_iJpw?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "In the Stillness of Morning",
      "date": "1992-12-01",
      "extension": ".jpg",
      "image": "26 x 1 - In the Stillness of Morning - 1992-12-01.jpg"
  },
  {
      "season": 26,
      "episode": 2,
      "link": "https://youtu.be/tWoInh2USOs?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Delightful Meadow Home",
      "date": "1992-12-08",
      "extension": ".jpg",
      "image": "26 x 2 - Delightful Meadow Home - 1992-12-08.jpg"
  },
  {
      "season": 26,
      "episode": 3,
      "link": "https://youtu.be/raAkJKeo0Sk?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "First Snow",
      "date": "1992-12-15",
      "extension": ".jpg",
      "image": "26 x 3 - First Snow - 1992-12-15.jpg"
  },
  {
      "season": 26,
      "episode": 4,
      "link": "https://youtu.be/KvJmSrErm20?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lake in the Valley",
      "date": "1992-12-22",
      "extension": ".jpg",
      "image": "26 x 4 - Lake in the Valley - 1992-12-22.jpg"
  },
  {
      "season": 26,
      "episode": 5,
      "link": "https://youtu.be/JMPwj3u4ZTA?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "A Trace of Spring",
      "date": "1992-12-29",
      "extension": ".jpg",
      "image": "26 x 5 - A Trace of Spring - 1992-12-29.jpg"
  },
  {
      "season": 26,
      "episode": 6,
      "link": "https://youtu.be/9BHWlbjd95c?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "An Arctic Winter Day",
      "date": "1993-01-05",
      "extension": ".jpg",
      "image": "26 x 6 - An Arctic Winter Day - 1993-01-05.jpg"
  },
  {
      "season": 26,
      "episode": 7,
      "link": "https://youtu.be/wKeemvioVrM?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "prb",
          "iy"
      ],
      "name": "Snow Birch",
      "date": "1993-01-12",
      "extension": ".jpg",
      "image": "26 x 7 - Snow Birch - 1993-01-12.jpg"
  },
  {
      "season": 26,
      "episode": 8,
      "link": "https://youtu.be/SLQXlFLoqQc?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Early Autumn",
      "date": "1993-01-19",
      "extension": ".jpg",
      "image": "26 x 8 - Early Autumn - 1993-01-19.jpg"
  },
  {
      "season": 26,
      "episode": 9,
      "link": "https://youtu.be/lSeRrm5ZK9c?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Tranquil Wooded Stream",
      "date": "1993-01-26",
      "extension": ".jpg",
      "image": "26 x 9 - Tranquil Wooded Stream - 1993-01-26.jpg"
  },
  {
      "season": 26,
      "episode": 10,
      "link": "https://youtu.be/nkDA_R-XmqA?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Purple Mountain Range",
      "date": "1993-02-02",
      "extension": ".jpg",
      "image": "26 x 10 - Purple Mountain Range - 1993-02-02.jpg"
  },
  {
      "season": 26,
      "episode": 11,
      "link": "https://youtu.be/EHD9u-lo2wc?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "br"
      ],
      "name": "Storm's A Comin",
      "date": "1993-02-09",
      "extension": ".jpg",
      "image": "26 x 11 - Storm's A Comin - 1993-02-09.jpg"
  },
  {
      "season": 26,
      "episode": 12,
      "link": "https://youtu.be/gMEZp47VKC0?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Sunset Aglow",
      "date": "1993-02-16",
      "extension": ".jpg",
      "image": "26 x 12 - Sunset Aglow - 1993-02-16.jpg"
  },
  {
      "season": 26,
      "episode": 13,
      "link": "https://youtu.be/RulXVcpgpqw?list=PLAEQD0ULngi5c9XxQCtE8zGh157RJWH39",
      "colors": [
          "mgb",
          "nmw",
          "tw",
          "prb",
          "mb",
          "sg",
          "cy"
      ],
      "name": "Evening at the Falls",
      "date": "1993-02-23",
      "extension": ".jpg",
      "image": "26 x 13 - Evening at the Falls - 1993-02-23.jpg"
  },
  {
      "season": 27,
      "episode": 1,
      "link": "https://youtu.be/0mJqzzeWyXs?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "iy",
          "br"
      ],
      "name": "Twilight Beauty",
      "date": "1993-03-02",
      "extension": ".jpg",
      "image": "27 x 1 - Twilight Beauty - 1993-03-02.jpg"
  },
  {
      "season": 27,
      "episode": 2,
      "link": "https://youtu.be/XlwfcXZ0AIU?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Angler's Haven",
      "date": "1993-03-09",
      "extension": ".jpg",
      "image": "27 x 2 - Angler's Haven - 1993-03-09.jpg"
  },
  {
      "season": 27,
      "episode": 3,
      "link": "https://youtu.be/3q8Zi9480lw?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "tw",
          "mb",
          "ac",
          "sg",
          "cy",
          "iy",
          "bu"
      ],
      "name": "Rustic Winter Woods",
      "date": "1993-03-16",
      "extension": ".jpg",
      "image": "27 x 3 - Rustic Winter Woods - 1993-03-16.jpg"
  },
  {
      "season": 27,
      "episode": 4,
      "link": "https://youtu.be/gxd0MPX8c6I?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Wilderness Falls",
      "date": "1993-03-23",
      "extension": ".jpg",
      "image": "27 x 4 - Wilderness Falls - 1993-03-23.jpg"
  },
  {
      "season": 27,
      "episode": 5,
      "link": "https://youtu.be/LeIVNKnWz7o?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Winter at the Farm",
      "date": "1993-03-30",
      "extension": ".jpg",
      "image": "27 x 5 - Winter at the Farm - 1993-03-30.jpg"
  },
  {
      "season": 27,
      "episode": 6,
      "link": "https://youtu.be/_kkZcIgocBM?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "tw",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Daisies at Dawn",
      "date": "1993-04-06",
      "extension": ".jpg",
      "image": "27 x 6 - Daisies at Dawn - 1993-04-06.jpg"
  },
  {
      "season": 27,
      "episode": 7,
      "link": "https://youtu.be/7R9HcaDT9P4?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "A Spectacular View",
      "date": "1993-04-13",
      "extension": ".jpg",
      "image": "27 x 7 - A Spectacular View - 1993-04-13.jpg"
  },
  {
      "season": 27,
      "episode": 8,
      "link": "https://youtu.be/crWDcTvDmec?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy"
      ],
      "name": "Daybreak",
      "date": "1993-04-20",
      "extension": ".jpg",
      "image": "27 x 8 - Daybreak - 1993-04-20.jpg"
  },
  {
      "season": 27,
      "episode": 9,
      "link": "https://youtu.be/Cg3XAVhAas0?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Island Paradise",
      "date": "1993-04-27",
      "extension": ".jpg",
      "image": "27 x 9 - Island Paradise - 1993-04-27.jpg"
  },
  {
      "season": 27,
      "episode": 10,
      "link": "https://youtu.be/xgjQ0v2d9mE?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "mgb",
          "tw",
          "mb",
          "vdb",
          "ac",
          "yo"
      ],
      "name": "Sunlight in the Shadows",
      "date": "1993-05-04",
      "extension": ".jpg",
      "image": "27 x 10 - Sunlight in the Shadows - 1993-05-04.jpg"
  },
  {
      "season": 27,
      "episode": 11,
      "link": "https://youtu.be/JdXCoxkOdwQ?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Splendor of a Snowy Winter",
      "date": "1993-05-11",
      "extension": ".jpg",
      "image": "27 x 11 - Splendor of a Snowy Winter - 1993-05-11.jpg"
  },
  {
      "season": 27,
      "episode": 12,
      "link": "https://youtu.be/wrbGlR22K0Q?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Forest River",
      "date": "1993-05-18",
      "extension": ".jpg",
      "image": "27 x 12 - Forest River - 1993-05-18.jpg"
  },
  {
      "season": 27,
      "episode": 13,
      "link": "https://youtu.be/qXElmiqzcI0?list=PLAEQD0ULngi6J8P64GAMRZSzucIru0rMG",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Golden Glow of Morning",
      "date": "1993-05-20",
      "extension": ".jpg",
      "image": "27 x 13 - Golden Glow of Morning - 1993-05-20.jpg"
  },
  {
      "season": 28,
      "episode": 1,
      "link": "https://youtu.be/VnZEpic2UzU?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Fisherman's Trail",
      "date": "1993-05-25",
      "extension": ".jpg",
      "image": "28 x 1 - Fisherman's Trail - 1993-05-25.jpg"
  },
  {
      "season": 28,
      "episode": 2,
      "link": "https://youtu.be/_TTdw3YnXuo?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "A Warm Winter",
      "date": "1993-06-01",
      "extension": ".jpg",
      "image": "28 x 2 - A Warm Winter - 1993-06-01.jpg"
  },
  {
      "season": 28,
      "episode": 3,
      "link": "https://youtu.be/lzODyJS2ZIg?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Under Pastel Skies",
      "date": "1993-06-08",
      "extension": ".jpg",
      "image": "28 x 3 - Under Pastel Skies - 1993-06-08.jpg"
  },
  {
      "season": 28,
      "episode": 4,
      "link": "https://youtu.be/zxj3xLDNxo0?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "ac",
          "sg",
          "iy"
      ],
      "name": "Golden Rays of Sunshine",
      "date": "1993-06-15",
      "extension": ".jpg",
      "image": "28 x 4 - Golden Rays of Sunshine - 1993-06-15.jpg"
  },
  {
      "season": 28,
      "episode": 5,
      "link": "https://youtu.be/bSm3fmEyJ20?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "The Magic of Fall",
      "date": "1993-06-22",
      "extension": ".jpg",
      "image": "28 x 5 - The Magic of Fall - 1993-06-22.jpg"
  },
  {
      "season": 28,
      "episode": 6,
      "link": "https://youtu.be/TohG7F8M3Ls?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Glacier Lake",
      "date": "1993-06-29",
      "extension": ".jpg",
      "image": "28 x 6 - Glacier Lake - 1993-06-29.jpg"
  },
  {
      "season": 28,
      "episode": 7,
      "link": "https://youtu.be/NYUIIBFj0iQ?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "The Old Weathered Barn",
      "date": "1993-07-06",
      "extension": ".jpg",
      "image": "28 x 7 - The Old Weathered Barn - 1993-07-06.jpg"
  },
  {
      "season": 28,
      "episode": 8,
      "link": "https://youtu.be/urHQRbRNuYI?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Deep Forest Falls",
      "date": "1993-07-13",
      "extension": ".jpg",
      "image": "28 x 8 - Deep Forest Falls - 1993-07-13.jpg"
  },
  {
      "season": 28,
      "episode": 9,
      "link": "https://youtu.be/mxJ2On9wyvY?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb"
      ],
      "name": "Winter's Grace",
      "date": "1993-07-20",
      "extension": ".jpg",
      "image": "28 x 9 - Winter's Grace - 1993-07-20.jpg"
  },
  {
      "season": 28,
      "episode": 10,
      "link": "https://youtu.be/hEyR2FDp-00?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Splendor of Autumn",
      "date": "1993-07-27",
      "extension": ".jpg",
      "image": "28 x 10 - Splendor of Autumn - 1993-07-27.jpg"
  },
  {
      "season": 28,
      "episode": 11,
      "link": "https://youtu.be/kN1DP_yqs-A?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Tranquil Seas",
      "date": "1993-08-03",
      "extension": ".jpg",
      "image": "28 x 11 - Tranquil Seas - 1993-08-03.jpg"
  },
  {
      "season": 28,
      "episode": 12,
      "link": "https://youtu.be/wc_YVijYjT4?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Mountain Serenity",
      "date": "1993-08-10",
      "extension": ".jpg",
      "image": "28 x 12 - Mountain Serenity - 1993-08-10.jpg"
  },
  {
      "season": 28,
      "episode": 13,
      "link": "https://youtu.be/22SiIDvyJeM?list=PLAEQD0ULngi6tej39ptiDqix2wd-W6glj",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Home before Nightfall",
      "date": "1993-08-17",
      "extension": ".jpg",
      "image": "28 x 13 - Home before Nightfall - 1993-08-17.jpg"
  },
  {
      "season": 29,
      "episode": 1,
      "link": "https://youtu.be/lLWEXRAnQd0?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Island in the Wilderness",
      "date": "1993-08-24",
      "extension": ".jpg",
      "image": "29 x 1 - Island in the Wilderness - 1993-08-24.jpg"
  },
  {
      "season": 29,
      "episode": 2,
      "link": "https://youtu.be/2XnIdinwot0?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Autumn Oval",
      "date": "1993-08-31",
      "extension": ".jpg",
      "image": "29 x 2 - Autumn Oval - 1993-08-31.jpg"
  },
  {
      "season": 29,
      "episode": 3,
      "link": "https://youtu.be/T2G5waMfQ-g?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Seasonal Progression",
      "date": "1993-09-07",
      "extension": ".jpg",
      "image": "29 x 3 - Seasonal Progression - 1993-09-07.jpg"
  },
  {
      "season": 29,
      "episode": 4,
      "link": "https://youtu.be/HOdS-G8p1KE?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "mgb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Light at the Summit",
      "date": "1993-09-14",
      "extension": ".jpg",
      "image": "29 x 4 - Light at the Summit - 1993-09-14.jpg"
  },
  {
      "season": 29,
      "episode": 5,
      "link": "https://youtu.be/G7Etn7QMeO4?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Countryside Barn",
      "date": "1993-09-21",
      "extension": ".jpg",
      "image": "29 x 5 - Countryside Barn - 1993-09-21.jpg"
  },
  {
      "season": 29,
      "episode": 6,
      "link": "https://youtu.be/dNEp3hoHSDI?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ac",
          "vdb",
          "sg",
          "cy"
      ],
      "name": "Mountain Lake Falls",
      "date": "1993-09-28",
      "extension": ".jpg",
      "image": "29 x 6 - Mountain Lake Falls - 1993-09-28.jpg"
  },
  {
      "season": 29,
      "episode": 7,
      "link": "https://youtu.be/Tnb2cXKKuWM?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "mgb",
          "tw",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Cypress Creek",
      "date": "1993-10-05",
      "extension": ".jpg",
      "image": "29 x 7 - Cypress Creek - 1993-10-05.jpg"
  },
  {
      "season": 29,
      "episode": 8,
      "link": "https://youtu.be/chhCkmp0RG8?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Trapper's Cabin",
      "date": "1993-10-12",
      "extension": ".jpg",
      "image": "29 x 8 - Trapper's Cabin - 1993-10-12.jpg"
  },
  {
      "season": 29,
      "episode": 9,
      "link": "https://youtu.be/pA_2paUhBAQ?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "yo",
          "br"
      ],
      "name": "Storm on the Horizon",
      "date": "1993-10-19",
      "extension": ".jpg",
      "image": "29 x 9 - Storm on the Horizon - 1993-10-19.jpg"
  },
  {
      "season": 29,
      "episode": 10,
      "link": "https://youtu.be/2zv_S_uVoVQ?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "mgb",
          "nmw",
          "black",
          "blue",
          "ac",
          "sg",
          "red",
          "orange",
          "yellow",
          "tw"
      ],
      "name": "Pot O' Posies",
      "date": "1993-10-26",
      "extension": ".jpg",
      "image": "29 x 10 - Pot O' Posies - 1993-10-26.jpg"
  },
  {
      "season": 29,
      "episode": 11,
      "link": "https://youtu.be/Io4fwhacpEs?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "A Perfect Winter Day",
      "date": "1993-11-02",
      "extension": ".jpg",
      "image": "29 x 11 - A Perfect Winter Day - 1993-11-02.jpg"
  },
  {
      "season": 29,
      "episode": 12,
      "link": "https://youtu.be/iRMsb9Vf7GM?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Aurora's Dance",
      "date": "1993-11-09",
      "extension": ".jpg",
      "image": "29 x 12 - Aurora's Dance - 1993-11-09.jpg"
  },
  {
      "season": 29,
      "episode": 13,
      "link": "https://youtu.be/ODrV0dfQcNs?list=PLAEQD0ULngi6c0D5_ELtW5p_NLShDktAN",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Woodman's Retreat",
      "date": "1993-11-16",
      "extension": ".jpg",
      "image": "29 x 13 - Woodman's Retreat - 1993-11-16.jpg"
  },
  {
      "season": 30,
      "episode": 1,
      "link": "https://youtu.be/ZHUdS0wEaKk?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Babbling Brook",
      "date": "1993-11-23",
      "extension": ".jpg",
      "image": "30 x 1 - Babbling Brook - 1993-11-23.jpg"
  },
  {
      "season": 30,
      "episode": 2,
      "link": "https://youtu.be/fcx1yUuSf3o?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "mgb",
          "nmw",
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Woodgrain's View",
      "date": "1993-11-30",
      "extension": ".jpg",
      "image": "30 x 2 - Woodgrain's View - 1993-11-30.jpg"
  },
  {
      "season": 30,
      "episode": 3,
      "link": "https://youtu.be/Xzv3iiWi1Wo?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "yo",
          "iy",
          "br"
      ],
      "name": "Winter's Peace",
      "date": "1993-12-07",
      "extension": ".jpg",
      "image": "30 x 3 - Winter's Peace - 1993-12-07.jpg"
  },
  {
      "season": 30,
      "episode": 4,
      "link": "https://youtu.be/vGsW_6BCukU?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Wilderness Trail",
      "date": "1993-12-14",
      "extension": ".jpg",
      "image": "30 x 4 - Wilderness Trail - 1993-12-14.jpg"
  },
  {
      "season": 30,
      "episode": 5,
      "link": "https://youtu.be/BSjee-ond7w?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "A Copper Winter",
      "date": "1993-12-21",
      "extension": ".jpg",
      "image": "30 x 5 - A Copper Winter - 1993-12-21.jpg"
  },
  {
      "season": 30,
      "episode": 6,
      "link": "https://youtu.be/LEz4UVL7POE?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Misty Foothills",
      "date": "1993-12-28",
      "extension": ".jpg",
      "image": "30 x 6 - Misty Foothills - 1993-12-28.jpg"
  },
  {
      "season": 30,
      "episode": 7,
      "link": "https://youtu.be/SrN4A9rVXj0?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Through the Window",
      "date": "1994-01-04",
      "extension": ".jpg",
      "image": "30 x 7 - Through the Window - 1994-01-04.jpg"
  },
  {
      "season": 30,
      "episode": 8,
      "link": "https://youtu.be/enutOy-nsZk?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Home in the Valley",
      "date": "1994-01-11",
      "extension": ".jpg",
      "image": "30 x 8 - Home in the Valley - 1994-01-11.jpg"
  },
  {
      "season": 30,
      "episode": 9,
      "link": "https://youtu.be/nXlu_Q0sR7c?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "br"
      ],
      "name": "Mountains of Grace",
      "date": "1994-01-18",
      "extension": ".jpg",
      "image": "30 x 9 - Mountains of Grace - 1994-01-18.jpg"
  },
  {
      "season": 30,
      "episode": 10,
      "link": "https://youtu.be/CY6sGFs209E?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "nmw",
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "iy"
      ],
      "name": "Seaside Harmony",
      "date": "1994-01-25",
      "extension": ".jpg",
      "image": "30 x 10 - Seaside Harmony - 1994-01-25.jpg"
  },
  {
      "season": 30,
      "episode": 11,
      "link": "https://youtu.be/jq8bIbpW7DQ?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "A Cold Spring Day",
      "date": "1994-02-01",
      "extension": ".jpg",
      "image": "30 x 11 - A Cold Spring Day - 1994-02-01.jpg"
  },
  {
      "season": 30,
      "episode": 12,
      "link": "https://youtu.be/eTEKGOi6SVg?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "tw",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Evening's Glow",
      "date": "1994-02-08",
      "extension": ".jpg",
      "image": "30 x 12 - Evening's Glow - 1994-02-08.jpg"
  },
  {
      "season": 30,
      "episode": 13,
      "link": "https://youtu.be/fz0YjqtHW84?list=PLAEQD0ULngi7EkzzzLDl33ibKHMDdUFc0",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy"
      ],
      "name": "Blue Ridge Falls",
      "date": "1994-02-15",
      "extension": ".jpg",
      "image": "30 x 13 - Blue Ridge Falls - 1994-02-15.jpg"
  },
  {
      "season": 31,
      "episode": 1,
      "link": "https://youtu.be/kJFB6rH3z2A?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Reflections of Calm",
      "date": "1994-02-22",
      "extension": ".jpg",
      "image": "31 x 1 - Reflections of Calm - 1994-02-22.jpg"
  },
  {
      "season": 31,
      "episode": 2,
      "link": "https://youtu.be/_MdMhQIOL1Y?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "Before the Snowfall",
      "date": "1994-03-01",
      "extension": ".jpg",
      "image": "31 x 2 - Before the Snowfall - 1994-03-01.jpg"
  },
  {
      "season": 31,
      "episode": 3,
      "link": "https://youtu.be/QDwd4pMYyuo?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "mgb",
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Winding Stream",
      "date": "1994-03-08",
      "extension": ".jpg",
      "image": "31 x 3 - Winding Stream - 1994-03-08.jpg"
  },
  {
      "season": 31,
      "episode": 4,
      "link": "https://youtu.be/7t6ue0pEcNE?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Tranquility Cove",
      "date": "1994-03-15",
      "extension": ".jpg",
      "image": "31 x 4 - Tranquility Cove - 1994-03-15.jpg"
  },
  {
      "season": 31,
      "episode": 5,
      "link": "https://youtu.be/KYlM2zJnNWY?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "mgb"
      ],
      "name": "Cabin in the Hollow",
      "date": "1994-03-22",
      "extension": ".jpg",
      "image": "31 x 5 - Cabin in the Hollow - 1994-03-22.jpg"
  },
  {
      "season": 31,
      "episode": 6,
      "link": "https://youtu.be/TLX1bmS8wBE?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "View from Clear Creek",
      "date": "1994-03-29",
      "extension": ".jpg",
      "image": "31 x 6 - View from Clear Creek - 1994-03-29.jpg"
  },
  {
      "season": 31,
      "episode": 7,
      "link": "https://youtu.be/ReENCTH7MYI?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Bridge to Autumn",
      "date": "1994-04-05",
      "extension": ".jpg",
      "image": "31 x 7 - Bridge to Autumn - 1994-04-05.jpg"
  },
  {
      "season": 31,
      "episode": 8,
      "link": "https://youtu.be/XWQrP-WiLgc?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "mgb",
          "nmw",
          "tw",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "yo"
      ],
      "name": "Trail's End",
      "date": "1994-04-12",
      "extension": ".jpg",
      "image": "31 x 8 - Trail's End - 1994-04-12.jpg"
  },
  {
      "season": 31,
      "episode": 9,
      "link": "https://youtu.be/mEU0stNfkxI?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Evergreen Valley",
      "date": "1994-04-19",
      "extension": ".jpg",
      "image": "31 x 9 - Evergreen Valley - 1994-04-19.jpg"
  },
  {
      "season": 31,
      "episode": 10,
      "link": "https://youtu.be/kMgd6r6c4vE?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "mgb",
          "tw",
          "pg",
          "pb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Balmy Beach",
      "date": "1994-04-26",
      "extension": ".jpg",
      "image": "31 x 10 - Balmy Beach - 1994-04-26.jpg"
  },
  {
      "season": 31,
      "episode": 11,
      "link": "https://youtu.be/8QWvzEQ69Kw?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Lake at the Ridge",
      "date": "1994-05-03",
      "extension": ".jpg",
      "image": "31 x 11 - Lake at the Ridge - 1994-05-03.jpg"
  },
  {
      "season": 31,
      "episode": 12,
      "link": "https://youtu.be/qx2IsmrCs3c?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "pb",
          "prb",
          "mb",
          "ds",
          "vdb",
          "ac"
      ],
      "name": "In the Midst of Winter",
      "date": "1994-05-10",
      "extension": ".jpg",
      "image": "31 x 12 - In the Midst of Winter - 1994-05-10.jpg"
  },
  {
      "season": 31,
      "episode": 13,
      "link": "https://youtu.be/nJGCVFn57U8?list=PLAEQD0ULngi5PAjhOL-GfvbcQDn2Hujoj",
      "colors": [
          "tw",
          "pb",
          "mb",
          "ds",
          "mgb",
          "vdb",
          "ac",
          "sg",
          "cy",
          "yo",
          "iy",
          "br"
      ],
      "name": "Wilderness Day",
      "date": "1994-5-17",
      "extension": ".jpg",
      "image": "31 x 13 - Wilderness Day - 1994-5-17.jpg"
  }
];

export const colors = [
    "Liquid White",
    "Liquid Black",
    "Alizarin Crimson",
    "Burnt Umber",
    "Bright Red",
    "Cadmium Yellow",
    "Dark Sienna",
    "Indian Yellow",
    "Midnight Black",
    "Phthalo Blue",
    "Phthalo Green",
    "Prussian Blue",
    "Sap Green",
    "Titanium White",
    "Van Dyke Brown",
    "Yellow Ochre"
]

export const subColorMap = {
    "Liquid White": ["mgw", "tw", "white"],
    "Liquid Black": ["mb", "mgb", "black"],
    "Alizarin Crimson": ["ac", "br", "red"],
    "Burnt Umber": ["bu", "ds", "vdb", "brown"],
    "Bright Red": ["ac", "br", "red"],
    "Cadmium Yellow": ["cy", "iy", "yo", "yellow"],
    "Dark Sienna": ["bu", "ds", "vdb", "brown"],
    "Indian Yellow": ["cy", "iy", "yo", "yellow", "orange"],
    "Midnight Black": ["mb", "mgb", "black"],
    "Phthalo Blue": ["mm", "pb", "prb", "blue"],
    "Phthalo Green": ["sg", "pg", "green"],
    "Prussian Blue": ["mm", "pb", "prb", "blue"],
    "Sap Green": ["sg", "pg", "green"],
    "Titanium White": ["mgw", "tw", "white"],
    "Van Dyke Brown": ["bu", "ds", "vdb", "brown"],
    "Yellow Ochre": ["cy", "iy", "yo", "yellow", "orange"],
}

export const colorMap = {
    "Liquid White": ["mgw", "white"],
    "Liquid Black": ["mgb", "black"],
    "Alizarin Crimson": ["ac", "red"],
    "Burnt Umber": ["bu", "brown"],
    "Bright Red": ["br", "red"],
    "Cadmium Yellow": ["cy", "yellow"],
    "Dark Sienna": ["ds", "brown"],
    "Indian Yellow": ["iy", "yellow", "orange"],
    "Midnight Black": ["mb"],
    "Phthalo Blue": ["pb", "blue"],
    "Phthalo Green": ["pg", "green"],
    "Prussian Blue": ["prb", "blue"],
    "Sap Green": ["sg", "green"],
    "Titanium White": ["tw", "white"],
    "Van Dyke Brown": ["vdb", "brown"],
    "Yellow Ochre": ["yo", "yellow", "orange"],
}